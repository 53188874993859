import React from "react";
import BaseComponent from "./BaseComponent";

export default class IsGranted extends BaseComponent {
  render() {
    if (this.context.isAuthorize(this.props.role)) {
      return this.props.children;
    }
    return <></>;
  }
}

export const Gender = {
  0: "Female",
  1: "Male",
  2: "Other",

  Male : 'Male',
  Female: 'Female',
  Other: 'Other',
};

export const Ethnicity = {
  0: "European",
  0: "Pakeha",
  0: "European/Pakeha",
  1: "Maori",
  2: "Pasifika",
  3: "Asian",
  4: "MELAA",
  5: "Other",

  European: "European",
  "European/Pasifika": 'European/Pasifika',
  "European/Pakeha": 'European/Pasifika',
  Maori: 'Maori',
  Pasifika: 'Pasifika',
  Asian: 'Asian',
  MELAA: 'MELAA',
  Other: 'Other',
  
};


export const School_level = {
  "Year 0": 0,
  "Year 1": 1,
  "Year 2": 2,
  "Year 3": 3,
  "Year 4": 4,
  "Year 5": 5,
  "Year 6": 6,
  "Year 7": 7,
  "Year 8": 8,
  "Year 9": 9,
  "Year 10": 10,
  "Year 11": 11,
  "Year 12": 12,
  "Year 13": 13,
  "Other": 14,

  0: "Year 0",
  1: "Year 1",
  2: "Year 2",
  3: "Year 3",
  4: "Year 4",
  5: "Year 5",
  6: "Year 6",
  7: "Year 7",
  8: "Year 8",
  9: "Year 9", 
 10: "Year 10",
 11: "Year 11",
 12: "Year 12",
 13: "Year 13",
 14: "Other"

};


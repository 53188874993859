import React, { useState } from "react";
import './zoom.css';

export const Icon = (props) => {
    
    const [width, setWidth] = useState(props.width); 
    const [height, setHeight] = useState(props.height);


    const handleClick = () => {
        props.handleClick(props.id, props.name)
    }

    let styles = {
        textAlign: "right", 
        fontSize: '13px'
    }

    let fill = props.color;
    
    if(props.styling) {
        styles.pointerEvents = props.styling.pointerEvents;
        fill = props.styling.fill;
    }
    return (
        <div  onClick={props.handleClick &&  handleClick}  style={styles} >
                <svg className="zoom" height={props.height} width={width} >
                    <circle  cx={width * 0.46875} cy={props.height * 0.46875} r={props.height * 0.375} stroke={fill} strokeWidth="4" fill={fill} />
                    <image className='img-circle' xlinkHref={props.icon} x={width * 0.15625} y={props.height * 0.15625} height={props.height * 0.625} width={width * 0.625}/>
                </svg>
                <p>{props.name}</p>
            </div>
    )
} 
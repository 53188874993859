import React from "react";
import CustomSelect from "./../Select";
import BaseComponent from "./../BaseComponent";

export default class TeacherEntry extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { access: {}, approved: false };
  }

  accessOptions = [
    { optionName: "Yes", optionVal: true },
    { optionName: "No", optionVal: false },
  ];

  componentDidMount() {
    this.setState({
      access: this.props.access ? this.accessOptions[0] : this.accessOptions[1],
      approved: this.props.approved,
    });
  }

  portalAccessChanged = (option) => {
    if (this.state.access.optionVal !== option.optionVal) {
      if (option.optionVal) {
        this.makeTeacherHead();
      } else {
        this.removeTeacherHead();
      }
    }
  };

  teacherApprovalChanged = (event) => {
    if (event.target.checked) {
      this.approveTeacher();
    } else {
      this.unapproveTeacher();
    }
  };

  // grant teacher head
  makeTeacherHead = () => {
    let url = `${process.env.REACT_APP_MAKE_TEACHER_HEAD_API}?user_uuid=${this.props.uuid}`;
    this.getRequest(
      url,
      this.teacherHeadSuccess,
      this.teacherHeadError,
      this.makeTeacherHead
    );
  };

  teacherHeadSuccess = (result) => {
    if (
      result.status === 200 &&
      result.data.success &&
      result.data.data.indexOf("head") > -1
    ) {
      this.setState({
        access: this.accessOptions[0],
      });
      this.props.reload();
    }
  };

  teacherHeadError = () => {
    this.setState({ error: true, errorMsg: "Something went wrong!" });
  };
  // end grant teacher head

  // revoke teacher head start
  removeTeacherHead = () => {
    let url = `${process.env.REACT_APP_REMOVE_TEACHER_HEAD_API}?user_uuid=${this.props.uuid}`;
    this.getRequest(
      url,
      this.removeTeacherHeadSuccess,
      this.removeTeacherHeadError,
      this.removeTeacherHead
    );
  };
  removeTeacherHeadSuccess = (result) => {
    if (
      result.status === 200 &&
      result.data.success &&
      result.data.data.indexOf("head") === -1
    ) {
      this.setState({
        access: this.accessOptions[1],
      });
      this.props.reload();
    }
  };
  removeTeacherHeadError = () => {
    this.setState({ error: true, errorMsg: "Something went wrong!" });
  };
  // end revoke teacher head

  // approve teacher start
  approveTeacher = () => {
    let url = `${process.env.REACT_APP_APPROVE_TEACHER_API}?user_uuid=${this.props.uuid}`;
    this.getRequest(
      url,
      this.approveSuccess,
      this.approveError,
      this.approveTeacher
    );
  };
  approveSuccess = (result) => {
    if (
      result.status === 200 &&
      result.data.success &&
      result.data.data.indexOf("teacher") > -1
    ) {
      this.setState({
        approved: true,
      });
      this.props.reload();
    }
  };
  approveError = () => {
    this.setState({ error: true, errorMsg: "Something went wrong!" });
  };
  // end approve teacher

  // unapprove teacher start
  unapproveTeacher = () => {
    let url = `${process.env.REACT_APP_UNAPPROVE_TEACHER_API}?user_uuid=${this.props.uuid}`;
    this.getRequest(
      url,
      this.unapproveTeacherSuccess,
      this.unapproveTeacherError,
      this.unapproveTeacher
    );
  };

  unapproveTeacherSuccess = (result) => {
    if (
      result.status === 200 &&
      result.data.success &&
      result.data.data.indexOf("teacher") === -1
    ) {
      this.setState({
        approved: false,
      });
      this.props.reload();
    }
  };

  unapproveTeacherError = () => {
    this.setState({ error: true, errorMsg: "Something went wrong!" });
  };
  // unapprove teacher end
  render() {
    return (
      <tr>
        <td className="text-left">
          {this.props.firstName} {this.props.lastName}
        </td>
        <td className="text-left">{this.props.email}</td>
        <td className="text-left">
          <div className="custom-select">
            <CustomSelect
              onSelect={this.portalAccessChanged}
              data={this.accessOptions}
              selectedOption={this.state.access}
            ></CustomSelect>
          </div>
        </td>
        <td className="text-left">
          <label
            className={`check-label ${!this.state.approved ? "error" : ""}`}
          >
            {this.state.approved ? "Approved" : "Waiting Approval"}
            <input
              type="checkbox"
              onChange={this.teacherApprovalChanged}
              checked={this.state.approved}
            />
            <span className="checkmark"></span>
          </label>
        </td>
        <td className="text-center">
          {this.props.observations}
        </td>
      </tr>
    );
  }
}

import React from "react";
import ManageTeachers from "../teachers/ManageTeachers";

class TeachersPage extends React.Component {
  render() {
    return <ManageTeachers {...this.props}></ManageTeachers>;
  }
}

export default TeachersPage;

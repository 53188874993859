import React from "react";
import BaseComponent from "./BaseComponent";

export default class Sort extends BaseComponent {
  nextSort = {
    "": "asc",
    asc: "dsc",
    dsc: "",
  };

  onSort = () => {
    this.props.onSort(this.props.property, this.nextSort[this.props.sort], this.props.type);
  };

  getAscClass = () => {
    return `arrow arrow-up ${this.props.sort}`;
  };

  getDscClass = () => {
    return `arrow arrow-down ${this.props.sort}`;
  };

  render() {
    return (
      <span className="sort-widget" onClick={this.onSort}>
        <span className={this.getAscClass()}></span>
        <span className={this.getDscClass()}></span>
      </span>
    );
  }
}

import { Component } from "react";
import axios from "axios";
import { CurrentDataContext } from "./../context/DataContext";

export default class BaseComponent extends Component {
  static contextType = CurrentDataContext;

  getRequest = (url, onResultFn, onErrorFn, replayFn, replayArgs) => {

    axios
      .get(url, { withCredentials: true })
      .then((result) => {
        if (onResultFn) {
          onResultFn(result);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(replayFn, replayArgs);
        } else {
          console.log(error);
          if (onErrorFn) {
            onErrorFn(error);
          }
        }
      });
  };

  postRequest = (url, data, onResultFn, onErrorFn, replayFn, replayArgs) => {
    axios
      .post(url, data, { withCredentials: true })
      .then((result) => {
        if (onResultFn) {
          onResultFn(result);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(replayFn, replayArgs);
        } else {
          console.log(error);
          if (onErrorFn) {
            onErrorFn(error);
          }
        }
      });
  };
}

import React, { forwardRef, useState } from "react";
import school from "./../../images/Smata_School-01.svg";
import { catToWhiteImageMap, catToBlueImageMap } from "./../../utils/Constants";
import SchoolTitle from "./../SchoolTitle";
import BaseComponent from "./../BaseComponent";
import ObservationTable from "./ObservationTable";
import Select from 'react-select';


import { DateRangePicker } from 'react-dates';
import LoadingSpinner from "../LoadingSpinner";







class Dashboard extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      highestGrowth: {},
      lowestGrowth: {},
      richest: {},
      observationCount: "",
      highestSpread: {},
      observations: [],
      allFilters: {},
      genderFilter: [],
      ethnicityFilter: [],
      studentFilter: [],
      yearlevelFilter: [],
      startDate: null,
      endDate: null,
      focusedInput: null,
      selectedStudents: [],
      selectedGenders:[],
      selectedEthnicity: [],
      selectedYearLevels: [],
      isLoading: false,
      isClearDisabled: true
    };
  }

  componentDidMount() {
    this.fetchSubjectData();
    this.fetchFilters();
  }


  clearAllFilters = (e) => {
    this.setState({
      selectedStudents: [],
      selectedGenders: [],
      selectedEthnicity: [],
      selectedYearLevels: [],
      startDate: null,
      endDate: null,
      focusedInput: null,
      isClearDisabled: true
    }, () => {
      this.fetchSubjectData();
    })
  }
  
  handleStudentsChange = (e) => {
    console.log('handleStudentsChange')
    this.setState({
      selectedStudents: e,
      isClearDisabled: false
    }, () => this.fetchSubjectData())
  }
  handleGenderChange = (e) => {
    console.log('handleGenderChange')
    this.setState({
      selectedGenders: e,
      isClearDisabled: false
    }, () => this.fetchSubjectData())
  }
  handleEthnicityChange = (e) => {
    console.log('handleEthnicityChange')
    this.setState({
      selectedEthnicity: e,
      isClearDisabled: false
    } , () => this.fetchSubjectData());
    
  }
  handleYearLevelChange = (e) => {
    console.log('handleYearLevelChange')
    this.setState({
      selectedYearLevels: e,
      isClearDisabled: false
    }, () => this.fetchSubjectData());
    
  }

  handleDateChange = (startDate, endDate) => {
    console.log('handleDateChange')
    this.setState({
      startDate: startDate,
      endDate: endDate,
      isClearDisabled: false
    }, () => {
      this.fetchSubjectData();
    })
  }



  fetchFilters = () => {
    let url = `${process.env.REACT_APP_DASHBOARD_FILTERS}`;

    this.setState({
      isLoading: true
    })

    this.getRequest(
      url,
      this.fetchFiltersSuccess,
      this.fetchFiltersError,
      this.fetchFilters
    )
  }

  fetchFiltersSuccess = (data) => {

    console.log("filterData", data)
    
    let body = data.data.data;
    this.setState({
      allFilters: body,
      genderFilter: body.gender.map((e, i) => { return { value: i, label: e } }),
      ethnicityFilter: body.ethnicity.map((e, i) => { return { value: i, label: e } }),
      studentFilter: body.students.map((e, i) => { return { value: e.id, label: e.first_name + " " + e.last_name } }),
      yearlevelFilter: body.year_levels.map((e, i) => { return { value: e, label: e } }),
      isLoading: false
    })
  }

  fetchFiltersError = (error) => {
    console.log("coming here")
    console.log(error, 'error Filter');
    this.setState({
      isLoading: false
    })
  }



  fetchSubjectData = () => {

    this.setState({
      isLoading: true
    })


    const [sd] = (this.state.startDate != null && this.state.startDate != undefined ? this.state.startDate.toISOString().split('T') : [""]);
    const [ed] = (this.state.endDate != null && this.state.endDate != undefined ? this.state.endDate.toISOString().split('T') : [""]);

    let students = "";
    let ethnicity = "";
    let gender = "";
    let year_level = "";
    let date_start = "";
    let date_end = "";

    if(sd){
      date_start = "&date_start="+sd
    }

    if(ed){
      date_end = "&date_end="+ed;
    }


    this.state.selectedStudents.map(e => students += "students=" + e.value + "&");
    this.state.selectedEthnicity.map(e => ethnicity += "ethnicity=" + e.value + "&");
    this.state.selectedGenders.map(e => gender += "gender=" + e.value + "&");
    this.state.selectedYearLevels.map(e => year_level += "year_level=" + e.value + "&");

    console.log(students);

    let url = `${process.env.REACT_APP_DASHBOARD}?${students}${ethnicity}${gender}${year_level}${date_start}${date_end}`;
    this.getRequest(
      url,
      this.fetchSubjectDataSuccess,
      this.fetchSubjectDataError,
      this.fetchSubjectData
    );
  };

  displayLabel = (value) => {
    return value || "Not enough data collected";
  }

  classOnDataAvailability = (data, classWhenAvailable, classWhenNoData) => {
    return data != null ? classWhenAvailable : classWhenNoData;
  }



  fetchSubjectDataSuccess = (result) => {

    if (result.status === 200 && result.data.success) {
      let highestGrowth = result.data.data?.highest_growth?.learning_area || {};
      let lowestGrowth = result.data.data?.lowest_growth?.learning_area || {};
      let richest =
        result.data.data?.richest_learning_area?.learning_area || {};
      let highestSpread = result.data.data?.biggest_spread?.learning_area || {};
      let observationCount = result.data.data?.number_of_observations;
      let observations = result.data.data?.observation_count;
      this.setState({
        highestGrowth,
        lowestGrowth,
        richest,
        observationCount,
        highestSpread,
        observations,
        isLoading: false
      });
    }
  };

  fetchSubjectDataError = () => { 
    this.setState({
      isLoading: false
    })
  };

  render() {



    



    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-4 page-heading d-flex">
              <div className="heading-img">
                <img src={school} alt="" />
              </div>
              <SchoolTitle title="Dashboard" />
            </div>

            <div className="col-8 row">


              <div className="filter_by__label col-12"><span>Filter By:</span> 
              {this.state.isLoading ? (<LoadingSpinner></LoadingSpinner>) : ""}


                <button className="btn" onClick={this.clearAllFilters} disabled={this.state.isClearDisabled}>Clear All</button>

              </div>

              <div className="col-3 basic__select__wrapper">
                <Select
                  placeholder="Students"
                  isMulti
                  name="colors"
                  options={this.state.allFilters ? this.state.studentFilter : []}
                  className="basic__select"
                  classNamePrefix="select"
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={this.handleStudentsChange}
                  value={this.state.selectedStudents}

                />
              </div>

              <div className="col-2 basic__select__wrapper">
                <Select
                  placeholder="Gender"
                  isMulti
                  name="colors"
                  options={this.state.allFilters ? this.state.genderFilter : []}
                  className="basic__select"
                  classNamePrefix="select"
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={this.handleGenderChange}
                  value={this.state.selectedGenders}

                />

              </div>
              <div className="col-2 basic__select__wrapper">
                <Select
                  placeholder="Ethnicity"
                  isMulti
                  name="colors"
                  options={this.state.allFilters ? this.state.ethnicityFilter : []}
                  className="basic__select ethnicity__select"
                  classNamePrefix="select"
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={this.handleEthnicityChange}
                  value={this.state.selectedEthnicity}
                />

              </div>
              <div className="col-2 basic__select__wrapper">
                <Select
                  placeholder="Year Level"
                  isMulti
                  name="colors"
                  options={this.state.allFilters ? this.state.yearlevelFilter : []}
                  className="basic__select"
                  classNamePrefix="select"
                  hideSelectedOptions={false}
                  isClearable={false}
                  height={20}
                  onChange={this.handleYearLevelChange}
                  value={this.state.selectedYearLevels}

                />

              </div>

              <div className="col-3 date__picker__container">

              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.handleDateChange( startDate, endDate )} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={() => false}
                displayFormat={() => "DD/MM/YYYY"}

              />

                
              </div>











            </div>
          </div>

          <div className="row section">
            <div className="col-4">
              <div className="feature-box">
                <div>
                  <img
                    src={catToWhiteImageMap[this.state.highestGrowth.category]}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <span className={this.classOnDataAvailability(this.state.highestGrowth.name, 'font-md-bold', 'font-sm')} >
                    {this.displayLabel(this.state.highestGrowth.name)}
                  </span>
                </div>
                <div className="mt-auto">
                  <span>Highest growth learning area</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-box">
                <div>
                  <img
                    src={catToWhiteImageMap[this.state.richest.category]}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <span className={this.classOnDataAvailability(this.state.richest.name, 'font-md-bold', 'font-sm')}>
                    {this.displayLabel(this.state.richest.name)}
                  </span>
                </div>
                <div className="mt-auto">
                  <span>Richest learning area</span>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-box">
                <div>
                  <img
                    src={catToWhiteImageMap[this.state.lowestGrowth.category]}
                    alt=""
                  />
                </div>
                <div className="mt-3">
                  <span className={this.classOnDataAvailability(this.state.lowestGrowth.name, 'font-md-bold', 'font-sm')}>
                    {this.displayLabel(this.state.lowestGrowth.name)}
                  </span>
                </div>
                <div className="mt-auto">
                  <span>Learning area with the least growth</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="row" style={{ height: "100%" }}>
                <div className="col-12 section">
                  <div className="feature-box side d-flex flex-column justify-content-around">
                    <div className="mt-3">
                      <span className={this.classOnDataAvailability(this.state.observationCount, 'font-lg-bold', 'font-sm')}>
                        {this.state.observationCount}
                      </span>
                    </div>
                    <div className="mt-auto">
                      <span>Number of observations</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 section">
                  <div className="feature-box side">
                    <div>
                      <img
                        src={
                          catToBlueImageMap[this.state.highestSpread.category]
                        }
                        alt=""
                      />
                    </div>
                    <div className="mt-3">
                      <span className={this.classOnDataAvailability(this.state.highestSpread.name, 'font-md-bold', 'font-sm')}>
                        {this.displayLabel(this.state.highestSpread.name)}
                      </span>
                    </div>
                    <div className="mt-auto">
                      <span>Learning area with the greatest variation</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12 section">
                  <ObservationTable observations={this.state.observations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Dashboard;




// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';




import React from "react";
import StudentForm from "../students/StudentForm";

class StudentAddPage extends React.Component {
  render() {
    return <StudentForm></StudentForm>;
  }
}

export default StudentAddPage;

import React from "react";
import BaseComponent from "../BaseComponent";

export default class ObservationEntry extends BaseComponent {
  render() {
    return (
      <tr>
        <td className="text-left">{this.props.name}</td>
        <td className="text-right">{this.props.count}</td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { setLastAttemptPath, getUser, isAuthenticated } from "./context/Auth";
import { CurrentDataContext } from "./context/DataContext";

class PrivateRoute extends Component {
  static contextType = CurrentDataContext;

  componentDidMount() {
    if (!this.isAuthorize()) {
      const { component: TargetComponent, ...componentProps } = this.props;
      const { path } = { ...componentProps };
      setLastAttemptPath(path);
    }
  }

  isAuthorize = () => {
    return this.context.isAuthorize(this.props.role);
  };

  renderTargetComponent = (props) => {
    const { component: TargetComponent } = this.props;
    if (this.isAuthorize()) {
      return <TargetComponent {...props} />;
    } else {
      let user = getUser();
      if(isAuthenticated() && user.roles.indexOf('admin') > -1 ) {
        return <Redirect to="/schools" />;
      } else if(isAuthenticated() && user.roles.indexOf('head') > -1 ) {
        return <Redirect to="/" />;
      } else {
        return <Redirect to="/login" />;
      }
      
    }
  };

  render() {
    const { component: TargetComponent, ...componentProps } = this.props;
    return <Route {...componentProps} render={this.renderTargetComponent} />;
  }
}

export default PrivateRoute;

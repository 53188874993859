import React from "react";
import student from "./../images/Smata_Student-01.svg";
import dashboard from "./../images/Smata_Dashboard-01.svg";
import addImg from "./../images/Smata_Add-01.svg";
import logoutImg from "./../images/Smata_Logout-01.svg";
import $ from "jquery";
import { logout } from "./../context/Auth";
import { Link, Switch } from "react-router-dom";
import Header from "./../components/header/header";
import DashbordPage from "./../components/pages/DashbordPage";
import TeachersPage from "./../components/pages/TeachersPage";
import StudentsPage from "./../components/pages/StudentsPage";
import TeacherAddPage from "./../components/pages/TeacherAddPage";
import StudentAddPage from "./../components/pages/StudentAddPage";
import SchoolsPage from "./../components/pages/SchoolsPage";
import PrivateRoute from "./../PrivateRoute";
import { CurrentDataContext } from "./../context/DataContext";
import IsGranted from "./IsGranted";
class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { model: "" };
  }
  static contextType = CurrentDataContext;

  componentDidMount() {
    $(".menu-icon").on("click", function () {
      let button = $(this);
      button.toggleClass("opened");
      $("body").toggleClass("side-bar-opened");
    });
  }

  getMenuClass = (path) => {
    return path === this.props.location.pathname
      ? "menu-item active"
      : "menu-item";
  };

  render() {
    return (
      <>
        <Header />
        <div className="content-wrapper">
          <div className="side-bar">
            <div className="container">
              <div className="profile">
                <div className="profile-img text-center">
                  <img src={student} alt="" />
                </div>
                <div className="text-center mt-3">
                  <span>{this.context.user?.name_first}</span>
                </div>
              </div>
              <div className="side-menu">
                <IsGranted role="head">
                  <div className={this.getMenuClass("/")}>
                    <Link to="/" className="d-flex">
                      <div className="menu-circle">
                        <img src={dashboard} alt="" />
                      </div>
                      <div>
                        <span>Data Dashboard</span>
                      </div>
                    </Link>
                  </div>
                </IsGranted>
                <IsGranted role="head">
                  <div className={this.getMenuClass("/teachers")}>
                    <Link to="/teachers" className="d-flex">
                      <div className="menu-circle">
                        <img src={student} alt="" />
                      </div>
                      <div>
                        <span>Manage Teachers</span>
                      </div>
                    </Link>
                  </div>
                </IsGranted>
                <IsGranted role="head">
                  <div className={this.getMenuClass("/students")}>
                    <Link to="/students" className="d-flex">
                      <div className="menu-circle">
                        <img src={addImg} alt="" />
                      </div>
                      <div>
                        <span>Manage Students</span>
                      </div>
                    </Link>
                  </div>
                </IsGranted>
                <IsGranted role="admin">
                  <div className={this.getMenuClass("/schools")}>
                    <Link to="/schools" className="d-flex">
                      <div className="menu-circle">
                        <img src={student} alt="" />
                      </div>
                      <div>
                        <span>Manage Schools</span>
                      </div>
                    </Link>
                  </div>
                </IsGranted>
              </div>
            </div>
            <button onClick={logout} className="footer-logout d-flex">
              <div className="footer-logout-circle">
                <img src={logoutImg} alt="" />
              </div>
              <div>
                <span>Log out</span>
              </div>
            </button>
          </div>
          {/* render content within menu tags (page body) here */}
          <div className="main-content">
            <Switch>
              <PrivateRoute
                path="/"
                exact
                component={DashbordPage}
                role="head"
              />
              <PrivateRoute
                path="/teachers"
                exact
                component={TeachersPage}
                role="head"
              />
              <PrivateRoute
                path="/students"
                exact
                component={StudentsPage}
                role="head"
              />
              <PrivateRoute
                path="/add-teacher"
                exact
                component={TeacherAddPage}
                role="head"
              />
              <PrivateRoute
                path="/add-student"
                exact
                component={StudentAddPage}
                role="head"
              />
              <PrivateRoute
                path="/schools"
                exact
                component={SchoolsPage}
                role="admin"
              />
              <PrivateRoute
                path="/schools/:id/teachers"
                exact
                component={TeachersPage}
                role="admin"
              />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default MainMenu;

import React from "react";
import student from "./../../images/Smata_Student-01.svg";
import close from "./../../images/Smata_Exit-01.svg";
import SchoolTitle from "./../SchoolTitle";
import CustomSelect from "./../Select";
import axios from "axios";
import { CurrentDataContext } from "./../../context/DataContext";
import { Link } from "react-router-dom";

class StudentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      gender: this.genderOptions[0],
      ethnicity: this.ethnicityOptions[0],
      school_level: this.schoolLevelOptions[0],
      name: "",
      lastName: "",
      error: false,
      errorMsg: "All fields are required!",
    };
  }

  static contextType = CurrentDataContext;

  genderOptions = [
    { optionName: "Select Gender", optionVal: "" },
    { optionName: "Female", optionVal: "0" },
    { optionName: "Male", optionVal: "1" },
    { optionName: "Other", optionVal: "2" },
  ];

  ethnicityOptions = [
    { optionName: "Select Ethnicity", optionVal: "" },
    { optionName: "European/Pakeha", optionVal: "0" },
    { optionName: "Maori", optionVal: "1" },
    { optionName: "Pasifika", optionVal: "2" },
    { optionName: "Asian", optionVal: "3" },
    { optionName: "MELAA", optionVal: "4" },
    { optionName: "Other", optionVal: "5" },
  ];

  schoolLevelOptions = [
    { optionName: "Select School Level", optionVal: ""},
    { optionName: "Year 0", optionVal: "0"},
    { optionName: "Year 1", optionVal: "1"},
    { optionName: "Year 2", optionVal: "2"},
    { optionName: "Year 3", optionVal: "3"},
    { optionName: "Year 4", optionVal: "4"},
    { optionName: "Year 5", optionVal: "5"},
    { optionName: "Year 6", optionVal: "6"},
    { optionName: "Year 7", optionVal: "7"},
    { optionName: "Year 8", optionVal: "8"},
    { optionName: "Year 9", optionVal: "9"},
    { optionName: "Year 10", optionVal: "10"},
    { optionName: "Year 11", optionVal: "11"},
    { optionName: "Year 12", optionVal: "12"},
    { optionName: "Year 13", optionVal: "13"},
    { optionName: "Other", optionVal: "14"}
  ]


  componentDidMount() {}

  submitStudent = () => {
    this.setState({ error: false, success: false });
    if (this._validForm()) {
      let url = process.env.REACT_APP_ADD_STUDENT_API;
      let { name, lastName, gender, ethnicity } = this.state;
      let formData = {
        first_name: name,
        last_name: lastName,
        gender: gender.optionName,
        ethnicity: ethnicity.optionName,
      };
      axios
        .post(url, formData, { withCredentials: true })
        .then((result) => {
          if (result.status === 200 && result.data.success) {
            this.setState({
              success: true,
              name: "",
              lastName: "",
              gender: this.genderOptions[0],
              ethnicity: this.ethnicityOptions[0],
            });
          } else {
            this.setState({
              error: true,
              errorMsg: "All fields are required!",
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            (error.response.status === 401 || error.response.status === 440)
          ) {
            // Unauthorized. Possible access token expiration
            this.context.refreshToken(this.submitStudent);
          } else {
            this.setState({ error: true, errorMsg: "Something went wrong!" });
          }
        });
    } else {
      this.setState({ error: true, errorMsg: "All fields are required!" });
    }
  };

  _validForm = () => {
    return (
      this.state.name &&
      this.state.lastName &&
      this.state.gender.optionVal &&
      this.state.ethnicity.optionVal
    );
  };

  onGenderSelect = (option) => {
    this.setState({ gender: option });
  };

  onEthnicitySelect = (option) => {
    this.setState({ ethnicity: option });
  };

  onSchoolLevelSelect = (option) => {
    this.setState({ school_level: option})
  }

  setName = (event) => {
    this.setState({ name: event.target.value });
  };

  setLastName = (event) => {
    this.setState({ lastName: event.target.value });
  };

  errorClass = () => {
    return this.state.error
      ? "text-center error"
      : "text-center  d-none  error";
  };

  successClass = () => {
    return this.state.success ? "text-center" : "text-center  d-none";
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 page-heading d-flex">
            <div className="heading-img">
              <img src={student} alt="" />
            </div>
            <SchoolTitle title="Manage Student" />
          </div>
        </div>

        <div className="row section">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="add-section section">
                  <form>
                    <div className="section-title clearfix">
                      <span>Add a student</span>
                      <Link to="/students">
                        <img src={close} alt="" />
                      </Link>
                    </div>
                    <div className="section-field mt-3 name">
                      <input
                        type="text"
                        value={this.state.name}
                        onChange={this.setName}
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="section-field mt-2 name">
                      <input
                        type="text"
                        value={this.state.lastName}
                        onChange={this.setLastName}
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="wide-select mt-2 custom-select">
                      <CustomSelect
                        onSelect={this.onGenderSelect}
                        data={this.genderOptions}
                        selectedOption={this.state.gender}
                      ></CustomSelect>
                    </div>
                    <div className="wide-select mt-2 custom-select">
                      <CustomSelect
                        onSelect={this.onEthnicitySelect}
                        data={this.ethnicityOptions}
                        selectedOption={this.state.ethnicity}
                      ></CustomSelect>
                    </div>
                    <div className="wide-select mt-2 custom-select">
                      <CustomSelect
                        onSelect={this.onSchoolLevelSelect}
                        data={this.schoolLevelOptions}
                        selectedOption={this.state.school_level}
                      ></CustomSelect>
                    </div>
                    <div className="section-btn mt-3 mb-2">
                      <button
                        type="button"
                        onClick={this.submitStudent}
                        className="btn"
                      >
                        Add student
                      </button>
                    </div>
                    <div className={this.errorClass()}>
                      <span className="alert-danger font-sm">
                        {this.state.errorMsg}
                      </span>
                    </div>
                    <div className={this.successClass()}>
                      <span className="alert-success font-sm">
                      Student added successfully
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudentForm;

import React from "react";
import CustomSelect from "./../Select";
import BaseComponent from "./../BaseComponent";
import deleteIcon from '../../images/Smata_Delete-01.svg';
import mail from '../../images/Smata_Mail-01.svg';
import {Icon} from './Icon';

export default class InvitedTeacherEntry extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
        onResendDelay: false, 
        onDeleteDelay: false, 
    }
  }

  handlePressEvent = (email, type) => {

    let url = `${process.env.REACT_APP_API_ROOT}`;

    if(type === 'Resend') {
      url += `/api/v1/teachers/resendInvitation`
      this.postRequest(url, {email: email});

      this.setState({onResendDelay: true})
  
      setInterval(() => {
        this.setState({onResendDelay: false})
      }, 2000)

    }else if(type === 'delete'){
      url +=  `/api/v1/teachers/deleteInvitation`
    
      this.postRequest(url, {id: this.props.id});
      this.props.reload();
    }
  }

  render() {
    
    const onPressCss = {
      fill: 'grey',
      pointerEvents: 'none'
    }

    const {onResendDelay, onDeleteDelay } = this.state;
    
    return (
      <tr>
        <td className="text-left">
          {this.props.firstName} {this.props.lastName}
        </td>
        <td className="text-left">{this.props.email}</td>
        <td className="text-left" style={{color: 'red'}}>
          Invitation Sent
        </td>
        <td>

        {onResendDelay ? 
            <label style={{color: 'red', paddingLeft: '20px'}}>Email Sent</label> : 
            <Icon id={this.props.email} handleClick={this.handlePressEvent} width={34} icon={mail} height={34} fontSize={2} name="Resend" color="#4068ae" />}
        </td>
        <td>
        {onDeleteDelay ? 
            <label style={{color: 'red', paddingLeft: '20px'}}>Deleted</label> : 
            <Icon styling={onResendDelay ?  onPressCss : null} handleClick={this.handlePressEvent} id={this.props.id} width={34} icon={deleteIcon} height={34} fontSize={2} name="delete" color="#4068ae" />
          }
        </td>
      </tr>
    );
  }
}

/**
 * style={{hover: "transform: scale(1.1);", transition: "all .1s ease-in-out"}}
 */

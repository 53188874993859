import React from "react";
import logo from "./../../images/Smata_LogoText-01.svg";
import { Link } from "react-router-dom";
class Header extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row menu-bg">
          <div className="col-12 m-auto menu-img">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="menu-icon opened"></div>
        </div>
      </div>
    );
  }
}
export default Header;

import React, { Component } from "react";
import axios from "axios";
import { CurrentDataContext } from "./../context/DataContext";
class SchoolTitle extends Component {
  constructor(props) {
    super(props);
    this.state = { schoolName: "" };
  }
  static contextType = CurrentDataContext;

  componentDidMount() {
    
    this.fetchScholl();
  }

  fetchScholl = () => {
    let schoolId = this.props.schoolId;
    let url;
    if(schoolId) {
      url = `${process.env.REACT_APP_ADMIN_SCHOOL_API}?school_id=${schoolId}`;
    } else {
      url = process.env.REACT_APP_SHCOOL_API;
    }
    
    axios
      .get(url, { withCredentials: true })
      .then((result) => {
        if (result.status === 200 && result.data.success) {
          this.setState({ schoolName: result.data.data.name });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.fetchScholl);
        } else {
          console.log(error);
        }
      });
  };

  render() {
    return (
      <>
        <div className="align-self-center">
          <div>
            <h1 className="first-title">{this.props.title}</h1>
          </div>
          <div>
            <h1 className="second-title">{this.state.schoolName}</h1>
          </div>
        </div>
      </>
    );
  }
}

export default SchoolTitle;

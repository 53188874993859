import React, { Component } from "react";
import axios from "axios";
import { CurrentDataContext } from "./../context/DataContext";
export default class UploadButton extends Component {
  constructor() {
    super();
    this.state = {
      refreshToggle: false,
      step: "selection",
      error: false,
      success: false,
    };
    this.fileSelector = React.createRef();
  }

  static contextType = CurrentDataContext;

  openFileBrowser = () => {
    this.setState({ error: false, success: false });
    this.fileSelector.current.click();
  };

  processFile = (event) => {
    this.setState({ step: "processing" });
    const fileList = event.target.files;
    for (const file of fileList) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this.processContent(event.target.result);
      });
      reader.readAsText(file);
    }
  };

  processContent = (content) => {

    if (content) {
      let studentLines = content.replace(/\r/gm, "")
      .split("\n")
      .filter((e) => e);

      let students = studentLines
        .filter((line) => line && line.split(",").length === 4)
        .map((line) => {
          let lineData = line.split(",").map((item) => item.trim());
          let student = {};
          student["first_name"] = lineData[0];
          student["last_name"] = lineData[1];
          student["ethnicity"] = lineData[3].charAt(0).toUpperCase() + lineData[3].slice(1).toLowerCase();
          student["gender"] = lineData[2].charAt(0).toUpperCase() + lineData[2].slice(1).toLowerCase();
          return student;
        });

      if (studentLines.length !== students.length) {
        this.setState({ error: true, step: "final" });
        return;
      }

      this.handleStudentsFromFile(students);
    } else {
      this.setState({ error: true, step: "final" });
    }
  };

  handleStudentsFromFile = (students) => {
    let url = process.env.REACT_APP_ADD_STUDENT_BULK_API;
    axios
      .post(url, { students }, { withCredentials: true })
      .then((result) => {
        if (result.status === 200 && result.data.success) {
          this.setState({ success: true, step: "final" });
          this.props.studentsUploadComplete();
        } else {
          this.setState({ error: true, step: "final" });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.handleStudentsFromFile, [students]);
        } else {
          console.log(error);
          this.setState({ error: true, step: "final" });
        }
      });
  };

  getFinalStepClass = () => {
    return this.state.error
      ? "btn upload text-center final alert-danger"
      : "btn upload final alert-success";
  };

  resetUploader = () => {
    this.setState({ step: "selection" });
  };

  render() {
    if (this.state.step === "selection") {
      return (
        <>
          <a onClick={this.openFileBrowser} className="btn upload">
            Upload CSV
          </a>
          <input
            onChange={this.processFile}
            type="file"
            ref={this.fileSelector}
            className="d-none"
            accept=".csv"
          />
        </>
      );
    } else if (this.state.step === "processing") {
      return (
        <>
          <a className="btn upload final">Uploading...</a>
        </>
      );
    } else if (this.state.step === "final") {
      return (
        <>
          <a onClick={this.resetUploader} className={this.getFinalStepClass()}>
            {this.state.error ? "Error" : "Completed!"}
          </a>
        </>
      );
    }
  }
}

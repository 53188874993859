import React, { Component } from "react";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.pageBtnCount = 4;
  }

  changePage = (btnNumber) => {
    let pageNumber = this.getPageNumberForBtn(btnNumber);
    if (this.props.currentPage !== pageNumber && pageNumber !== "-") {
      this.props.loadPage(pageNumber, this.props.type);
    }
  };

  changeToNextPage = () => {
    let totalPages = Math.ceil(this.props.totalRecords / this.props.pageSize);
    let nextPage = this.props.currentPage + 1;
    if (nextPage <= totalPages) {
      this.props.loadPage(nextPage, this.props.type);
    }
  };

  changeToPrevPage = () => {
    let prevPage = this.props.currentPage - 1;
    if (prevPage > 0) {
      this.props.loadPage(prevPage, this.props.type);
    }
  };

  btnClass = (btnNumber) => {
    return this.getPageNumberForBtn(btnNumber) === this.props.currentPage
      ? "font-sm active"
      : "font-sm";
  };

  getPageNumberForBtn = (btnNumber) => {
    let totalPages = Math.ceil(this.props.totalRecords / this.props.pageSize);
    let paginationStartPage =
      this.props.currentPage - 2 > 0
        ? this.props.currentPage - 2
        : this.props.currentPage - 1 > 0
        ? this.props.currentPage - 1
        : this.props.currentPage;
    return paginationStartPage + btnNumber - 1 <= totalPages
      ? paginationStartPage + btnNumber - 1
      : "-";
  };

  render() {
    
    const startEntry =
      (this.props.currentPage - 1) * this.props.pageSize + 1 <=
      this.props.totalRecords
        ? (this.props.currentPage - 1) * this.props.pageSize + 1
        : this.props.totalRecords;
    const lastEntry =
      this.props.currentPage * this.props.pageSize <= this.props.totalRecords
        ? this.props.currentPage * this.props.pageSize
        : this.props.totalRecords;
    return (
      <div className="d-flex justify-content-between paging mt-3">
        <div>
          <span className="font-sm">
            Showing {startEntry} to {lastEntry} of {this.props.totalRecords}{" "}
            entries
          </span>
        </div>
        <div className="clearfix">
          <span className="float-left"></span>
          <ul className="d-flex">
            <li onClick={() => this.changeToPrevPage()} className="font-sm">
              Previous
            </li>
            <li onClick={() => this.changePage(1)} className={this.btnClass(1)}>
              {this.getPageNumberForBtn(1)}
            </li>
            <li onClick={() => this.changePage(2)} className={this.btnClass(2)}>
              {this.getPageNumberForBtn(2)}
            </li>
            <li onClick={() => this.changePage(3)} className={this.btnClass(3)}>
              {this.getPageNumberForBtn(3)}
            </li>
            <li onClick={() => this.changePage(4)} className={this.btnClass(4)}>
              {this.getPageNumberForBtn(4)}
            </li>
            <li onClick={() => this.changeToNextPage()} className="font-sm">
              Next
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Pagination;

import React from "react";
import student from "./../../images/Smata_Student-01.svg";
import close from "./../../images/Smata_Exit-01.svg";
import SchoolTitle from "./../SchoolTitle";
import BaseComponent from "./../BaseComponent";
import { Link } from "react-router-dom";
class TeacherForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      name_first: "",
      name_last: "",
      email: "",
      error: false,
      errorMsg: "All fields are required!",
    };
  }

  setName = (event) => {
    this.setState({ name_first: event.target.value });
  };

  setLastName = (event) => {
    this.setState({ name_last: event.target.value });
  };

  setEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  submitTeacher = () => {
    this.setState({ error: false, success: false });
    if (this._validForm()) {
      let url = process.env.REACT_APP_INVITE_TEACHER_API;
      const { name_first, name_last, email } = this.state;
      this.postRequest(
        url,
        { name_first, name_last, email },
        this.submitTeacherSuccess,
        this.submitTeacherError,
        this.submitTeacher
      );
    } else {
      this.setState({ error: true, errorMsg: "All fields are required!" });
    }
  };

  submitTeacherSuccess = (result) => {
    if (result.status === 200 && result.data.success) {
      this.setState({
        success: true,
        name_first: "",
        name_last: "",
        email: "",
      });
    } else {
      this.setState({ error: true, errorMsg: "All fields are required!" });
    }
  };

  submitTeacherError = () => {
    this.setState({ error: true, errorMsg: "Something went wrong!" });
  };

  _validForm() {
    return (
      this.state.name_first &&
      this.state.name_last &&
      this._validEmail(this.state.email)
    );
  }

  _validEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  errorClass = () => {
    return this.state.error
      ? "text-center error"
      : "text-center  d-none  error";
  };

  successClass = () => {
    return this.state.success ? "text-center" : "text-center  d-none";
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 page-heading d-flex">
            <div className="heading-img">
              <img src={student} alt="" />
            </div>
            <SchoolTitle title="Manage Teacher" />
          </div>
        </div>

        <div className="row section">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="add-section section">
                  <form>
                    <div className="section-title clearfix">
                      <span>Add a teacher</span>
                      <Link to="/teachers">
                        <img src={close} alt="" />
                      </Link>
                    </div>
                    <div className="section-field mt-3 name">
                      <input
                        type="text"
                        value={this.state.name_first}
                        onChange={this.setName}
                        placeholder="First Name"
                      />
                    </div>
                    <div className="section-field mt-2 name">
                      <input
                        type="text"
                        value={this.state.name_last}
                        onChange={this.setLastName}
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="section-field mt-2 email">
                      <input
                        type="text"
                        value={this.state.email}
                        onChange={this.setEmail}
                        placeholder="Email"
                      />
                    </div>
                    <div className="section-btn mt-3 mb-2">
                      <button
                        type="button"
                        className="btn"
                        onClick={this.submitTeacher}
                      >
                        Add teacher
                      </button>
                    </div>
                    <div className={this.errorClass()}>
                      <span className="alert-danger font-sm">
                        {this.state.errorMsg}
                      </span>
                    </div>
                    <div className={this.successClass()}>
                      <span className="alert-success font-sm">
                        Teacher invited
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeacherForm;

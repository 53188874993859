import React from "react";
import teacher from "./../../images/Smata_Teacher2-01.svg";
import { Link } from "react-router-dom";
import SchoolTitle from "./../SchoolTitle";
import axios from "axios";
import TeacherEntry from "./TeacherEntry";
import Pagination from "./../Pagination";
import { CurrentDataContext } from "./../../context/DataContext";
import Sort from "./../Sort";
import IsGranted from "../IsGranted";
import { TeachersTable } from "./TeachersTable";
import { InvitationTeachersTable } from './InvitationTeachersTable';
import InvitedTeacherEntry from "./InvitedTeacherEntry";
import UploadTeachersButton from "../UploadTeacherButton";

class ManageTeachers extends React.Component {
  constructor(props) {
    super(props);
    this.schoolId = this.props.match.params.id;
    this.state = {
      teachersRaw: null,
      teachers: null,
      invitedTeachers: null,
      invitedTeachersRaw: null,
      currentPage: 1,
      invitedTeacherCurrentPage: 1,
      pageSize: 10,
      total: 0,
      invitedTeachersTotal: 0,
      searchKey: "",
      sort: { full_name: "", email: "", access: "", approved: "" },
      sortMethod: "",
      sortProperty: "",
    };
  }

  static contextType = CurrentDataContext;

   componentDidMount() {
    this.fetchInvitedTeachers(this.state.currentPage);
     this.fetchTeachers(this.state.currentPage);
  }

  fetchInvitedTeachers = () => {
    let url = `${process.env.REACT_APP_API_ROOT}`;
    if (this.schoolId) {
      url += `/api/v1/invitedTeachers?school_id=${this.schoolId}&page_number=0&page_size=100000&unverified=true`;
    } else {
      url += `/api/v1/invitedTeachers?page_number=0&page_size=100000&&unverified=true`;
    }


     axios
      .get(url, { withCredentials: true })
      .then((result) => {
        if (result.status === 200 && result.data.success) {
          let { invitedTeachers, total } = result.data.data;
          this.setState({
            invitedTeachersRaw: invitedTeachers,
            total,
          });
          this.renderTeachersForPage(this.state.invitedTeacherCurrentPage, "invitedTeachers");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.fetchInvitedTeachers);
        } else {
          console.log(error);
        }
      });
  };

  fetchTeachers = () => {
    let url;
    if (this.schoolId) {
      url = `${process.env.REACT_APP_ADMIN_TEACHERS_API}?school_id=${this.schoolId}&page_number=0&page_size=100000&unverified=true`;
    } else {
      url = `${process.env.REACT_APP_TEACHERS_API}?page_number=0&page_size=100000&&unverified=true`;
    }

     axios
      .get(url, { withCredentials: true })
      .then((result) => {
        if (result.status === 200 && result.data.success) {
          let { teachers, total } = result.data.data;
          this.setState({
            teachersRaw: teachers,
            total,
          });
          this.renderTeachersForPage(this.state.currentPage, "teachersRaw");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.fetchTeachers);
        } else {
          console.log(error);
        }
      });
  };

  renderTeachersForPage = (page, type) => {

    let start = (page - 1) * this.state.pageSize;
    let end = page * this.state.pageSize;
    let searchKey = this.state.searchKey.toLowerCase();

    let data;
    if(type === 'teachersRaw'){
      data = this.state.teachersRaw;
    }else {
      data = this.state.invitedTeachersRaw;
    }

    let teachersSearched = data.filter((teacher) =>
      this.state.searchKey
        ? (teacher.name_first &&
            teacher.name_first.toLowerCase().includes(searchKey)) ||
          (teacher.name_last &&
            teacher.name_last.toLowerCase().includes(searchKey))
        : true
    );
    let teachersSorted = teachersSearched;
    // if (this.state.sortProperty && this.state.sortMethod) {
    //   teachersSorted = teachersSearched.sort((a, b) => {
    //     let propA, propB;
    //     if (this.state.sortProperty === "full_name") {
    //       propA = `${a["name_first"]} ${a["name_last"]}`;
    //       propB = `${b["name_first"]} ${b["name_last"]}`;
    //     } else if (
    //       type === "teachersRaw" &&
    //       this.state.sortProperty === "access"
    //     ) {
    //       propA = a.roles.indexOf("head") > -1 ? "yes" : "no";
    //       propB = b.roles.indexOf("head") > -1 ? "yes" : "no";
    //     } else if (
    //       type === "teachersRaw" &&
    //       this.state.sortProperty === "approved"
    //     ) {
    //       propA = a.roles.indexOf("teacher") > -1 ? "Approved" : "Waiting";
    //       propB = b.roles.indexOf("teacher") > -1 ? "Approved" : "Waiting";
    //     } else {
    //       propA = a[this.state.sortProperty];
    //       propB = b[this.state.sortProperty];
    //     }
    //     if (this.state.sortMethod === "asc") {
    //       return propA && !propB
    //         ? 1
    //         : propA && propB && propA.toLowerCase() > propB.toLowerCase()
    //         ? 1
    //         : -1;
    //     } else {
    //       return propA && !propB
    //         ? -1
    //         : propA && propB && propA.toLowerCase() > propB.toLowerCase()
    //         ? -1
    //         : 1;
    //     }
    //   });
    // }

    if (type === "teachersRaw") {
      let teachersForPage = teachersSorted
        .slice(start, end)
        .map((teacher) => (
          <TeacherEntry
            key={teacher.uuid}
            uuid={teacher.uuid}
            firstName={teacher.name_first}
            lastName={teacher.name_last}
            email={teacher.email}
            access={teacher.roles.indexOf("head") > -1}
            approved={teacher.roles.indexOf("teacher") > -1}
            reload={this.fetchTeachers}
            observations={teacher.totalObservations}
          ></TeacherEntry>
        ));
      this.setState({
        teachers: teachersForPage,
        currentPage: page,
        total: teachersSorted.length,
      });
  }else {

    let teachersForPage = teachersSorted
      .slice(start, end)
      .map((teacher) => {
       return (
        <InvitedTeacherEntry
          key={teacher.id}
          id={teacher.id}
          firstName={teacher.name_first}
          lastName={teacher.name_last}
          email={teacher.email_address}
          reload={this.fetchInvitedTeachers}
        ></InvitedTeacherEntry>
      )});

    this.setState({
      invitedTeachers: teachersForPage,
      invitedTeacherCurrentPage: page,
      invtedTeachersTotal: teachersSorted.length,
    });
  }
}
    
    searchChanged = (event) => {
      this.setState({ searchKey: event.target.value }, () => {
      this.renderTeachersForPage(1, 'teachersRaw')
      this.renderTeachersForPage(1, 'invitedTeachersRaw')
      });
  };

  sort = (proporty, sort, type) => {
    const newSortState = this._getFreshSortState();
    newSortState[proporty] = sort;
    this.setState(
      { sort: newSortState, sortProperty: proporty, sortMethod: sort },
      () => this.renderTeachersForPage(this.state.currentPage, type)
    );
  };

  _getFreshSortState = () => {
    return {
      full_name: "",
      email: "",
      access: "",
      approved: "",
    };
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 page-heading d-flex align-items-center">
            <div className="heading-img">
              <img src={teacher} alt="" />
            </div>
            <SchoolTitle title="Manage Teachers" schoolId={this.schoolId} />

            <div className="top-inputs ml-auto">
              <IsGranted role="head,admin">
                <div className="text-right mb-3">
                  <Link to="/add-teacher" className="btn add mr-2">
                    Add a teacher
                  </Link>

                  <UploadTeachersButton teachersUploadComplete={this.fetchInvitedTeachers}></UploadTeachersButton>
                </div>
              </IsGranted>
              <div className="text-right search">
                <input
                  value={this.state.searchKey}
                  onChange={this.searchChanged}
                  type="text"
                  placeholder="Search by name"
                />
              </div>
            </div>
          </div>
        </div>
        <TeachersTable
          type="teachersRaw"
          teachers={this.state.teachers}
          currentPage={this.state.currentPage}
          totalRecords={this.state.total}
          pageSize={this.state.pageSize}
          loadPage={this.renderTeachersForPage}
          sort={this.sort}
        />

        <h5>Invited Teachers</h5>

        <InvitationTeachersTable
          type="invitedTeachersRaw"
          teachers={this.state.invitedTeachers}
          currentPage={this.state.invitedTeacherCurrentPage}
          totalRecords={this.state.invtedTeachersTotal}
          pageSize={this.state.pageSize}
          loadPage={this.renderTeachersForPage}
          sort={this.sort}
        />
      </div>
    );
  }
}

export default ManageTeachers;

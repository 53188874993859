import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomSelect from "./../Select";
import BaseComponent from "./../BaseComponent";
import teacher from "./../../images/Smata_Teacher2-01.svg";
import { Link } from "react-router-dom";

export default class SchoolEntry extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: props.schoolName || '',
      billingEmail: props.billingEmail || '',
      access: {},
      approved: false,
      renewDate: props.renewDate ? new Date(props.renewDate) : null
    };
  }

  accessOptions = [
    { optionName: "Yes", optionVal: true },
    { optionName: "No", optionVal: false },
  ];

  componentDidMount() {
    this.setState({
      access: this.props.access ? this.accessOptions[0] : this.accessOptions[1],
      approved: this.props.approved,
    });
  }
  
  inputChanged = (event, field) => {
    this.setState({ [field]: event.target.value });
  };

  updateInputChange = (field) => {
    if(this.state[field] !== this.props[field]) {
      this.updateSchoolRecord();
    }
  }

  renewDateChanged = (renewDate) => {
    this.setState({ renewDate });
  }

  updateRenewDate = () => {
    if(this.state.renewDate && this.state.renewDate.getTime() !== this.props.renewDate) {
      this.updateSchoolRecord();
    }
  }

  schoolApprovalChanged = (event) => {
    this.setState({approved : event.target.checked}, this.updateSchoolRecord);
  };

  schoolAccessChanged = (option) => {
    if (this.state.access.optionVal !== option.optionVal) {
      this.setState({access: option}, this.updateSchoolRecord);      
    }
  }

  updateSchoolRecord = () => {
    let url = process.env.REACT_APP_SCHOOL_EDIT_API;
    let record = { "id": this.props.id, "name": this.state.schoolName, "admin": { "free_access": this.state.access.optionVal, "billing_email": this.state.billingEmail, "renewal_date": this.state.renewDate ? this.state.renewDate.getTime() : 0, "school_enabled": this.state.approved } }
    this.postRequest(
      url,
      record,
      this.recordUpdateSuccess,
      this.recordUpdateError,
      this.updateSchoolRecord
    );
  };

  recordUpdateSuccess = (result) => {
    if (result.status === 200) {
      this.props.reload();
    }
  };
  recordUpdateError = () => {
    this.setState({ error: true, errorMsg: "Something went wrong!" });
  };

  render() {
    return (
      <tr>
        <td className="text-left">
          <div className="round-input">
            <input
              type="text"
              value={this.state.schoolName}
              onChange={(event) => this.inputChanged(event, "schoolName")}
              onBlur={ () => {this.updateInputChange("schoolName")} }
            />
          </div>
        </td>
        <td className="text-left">
          <div className="round-input">
            <input
              type="text"
              value={this.state.billingEmail}
              onChange={(event) => this.inputChanged(event, "billingEmail")}
              onBlur={ () => {this.updateInputChange("billingEmail")} }
            />
          </div>
        </td>
        <td className="text-left">{this.props.schoolEmail}</td>
        <td className="text-left">
          <label
            className={`check-label ${!this.state.approved ? "error" : ""}`}
          >
            {this.state.approved ? "Approved" : "Waiting Approval"}
            <input
              type="checkbox"
              onChange={this.schoolApprovalChanged}
              checked={this.state.approved}
            />
            <span className="checkmark"></span>
          </label>
        </td>

        <td className="text-left">
          <DatePicker 
          selected={this.state.renewDate} 
          onChange= {this.renewDateChanged}
          onCalendarClose={this.updateRenewDate}
          className="pz-date-picker"
          dateFormat="dd/MM/yyy"/>          
        </td>

        <td className="text-left">
          <div className="custom-select">
            <CustomSelect
              onSelect={this.schoolAccessChanged}
              data={this.accessOptions}
              selectedOption={this.state.access}
            ></CustomSelect>
          </div>
        </td>
        <td className="text-center">{this.props.observations}</td>
        <td className="text-center">
          <Link to={`/schools/${this.props.id}/teachers`}>
            <div className="view-button">
              <img src={teacher} alt="" />
            </div>
          </Link>
        </td>
      </tr>
    );
  }
}

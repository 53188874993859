import React from "react";
import student from "./../../images/Smata_Student-01.svg";
import { Link } from "react-router-dom";
import SchoolTitle from "./../SchoolTitle";
import axios from "axios";
import { CurrentDataContext } from "./../../context/DataContext";
import StudentEntry from "./StudentEntry";
import Pagination from "./../Pagination";
import UploadButton from "./../UploadButton";
import Sort from "./../Sort";
import { Gender, Ethnicity } from "./../../utils/Enums";
import StudentUploadModal from "./StudentUploadModal";
class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentsRaw: null,
      students: null,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      searchKey: "",
      sort: {
        first_name: "",
        last_name: "",
        gender: "",
        ethnicity: "",
        school_level: "",
      },
      sortMethod: "",
      sortProperty: "",
      studentsMap: new Map(),
    };
  }
  static contextType = CurrentDataContext;

  componentDidMount() {
    if (this.props.displayAll) {
      this.setState(
        {
          // students: this.props.students,
          studentsRaw: this.props.studentsArray,
          pageSize: 6,
        },
        () => {
          this.renderStudentForPage(1, "");
        }
      );
    } else {
      this.fetchStudents();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.studentsArray === undefined) {
      return;
    }

    const hasChanged = prevProps.studentsArray.filter((student, index) => {
      return student.checked !== this.props.studentsArray[index].checked;
    });

    if (this.props.studentsArray && hasChanged.length > 0) {
      this.setState(
        { studentsRaw: this.props.studentsArray, pageSize: 6 },
        () => this.renderStudentForPage(1, "")
      );
    }
  }

  fetchStudents = () => {
    let url = `${process.env.REACT_APP_STUDENTS_API}?page_number=0&page_size=100000`;
    axios
      .get(url, { withCredentials: true })
      .then((result) => {
        if (result.status === 200 && result.data.success) {
          let { students, total } = result.data.data;

          students.forEach((e, i) => {
            if (e.first_name == null || e.first_name == undefined)
              e.first_name = "";
            if (e.last_name == null || e.last_name == undefined)
              e.last_name = "";
          });

          const mappedStudents = students.reduce((map, student) => {
            map[`${student.first_name}${student.last_name}`] = student;
            return map;
          }, {});

          this.setState({
            studentsRaw: students,
            studentsMap: mappedStudents,
            total,
          });
          this.renderStudentForPage(this.state.currentPage);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.fetchStudents);
        } else {
          console.log(error);
        }
      });
  };

  renderStudentForPage = (page, type) => {
    let start = (page - 1) * this.state.pageSize;
    let end = page * this.state.pageSize;

    let searchKey = this.state.searchKey.toLowerCase();
    let studentSearched = this.state.studentsRaw.filter((student) =>
      this.state.searchKey
        ? student.first_name.toLowerCase().includes(searchKey) ||
          student.last_name.toLowerCase().includes(searchKey)
        : true
    );
    let studentSorted = studentSearched;
    if (this.state.sortProperty && this.state.sortMethod) {
      studentSorted = studentSearched.sort((a, b) => {
        let propA = a[this.state.sortProperty];
        let propB = b[this.state.sortProperty];
        if (this.state.sortProperty === "gender") {
          propA = Gender[propA];
          propB = Gender[propB];
        } else if (this.state.sortProperty === "ethnicity") {
          propA = Ethnicity[propA];
          propB = Ethnicity[propB];
        }
        if (this.state.sortMethod === "asc") {
          return propA && !propB
            ? 1
            : propA && propB && propA.toLowerCase() > propB.toLowerCase()
            ? 1
            : -1;
        } else {
          return propA && !propB
            ? -1
            : propA && propB && propA.toLowerCase() > propB.toLowerCase()
            ? -1
            : 1;
        }
      });
    }
    let studetsForPage = studentSorted
      .slice(start, end)
      .map((student, index) => (
        <StudentEntry
          key={student.id}
          index={index}
          firstName={student.first_name}
          lastName={student.last_name}
          gender={student.gender}
          ethnicity={student.ethnicity}
          school_level={student.school_level}
          checkbox={type}
          handleCheckbox={this.props.handleSingleCheckbox}
          checked={student.checked}
          modal={this.props.displayAll ? true : false}
          handleSave={this.props.handleSave}
          student_id={student.id}
          duplicate={student.duplicate}
        ></StudentEntry>
      ));

    this.setState({
      students: studetsForPage,
      currentPage: page,
      total: studentSorted.length,
    });
  };

  fetchNewStudents = () => {
    this.fetchStudents();
  };

  searchChanged = (event) => {
    this.setState({ searchKey: event.target.value }, () =>
      this.renderStudentForPage(1)
    );
  };

  sort = (proporty, sort, type) => {
    const newSortState = this._getFreshSortState();
    newSortState[proporty] = sort;
    this.setState(
      { sort: newSortState, sortProperty: proporty, sortMethod: sort },
      () => this.renderStudentForPage(this.state.currentPage, type)
    );
  };

  _getFreshSortState = () => {
    return {
      first_name: "",
      last_name: "",
      gender: "",
      ethnicity: "",
      school_level: "",
    };
  };

  render() {
    const { displayAll } = this.props;

    return (
      <div className="container">
        {!displayAll ? (
          <div className="row">
            <div className="col-12 page-heading d-flex align-items-center">
              <div className="heading-img">
                <img src={student} alt="" />
              </div>
              <SchoolTitle title="Manage Students" />
              <div className="top-inputs ml-auto">
                <div className="text-right mb-3 clearfix">
                  <Link to="/add-student" className="btn add float-left">
                    Add a student
                  </Link>

                  <StudentUploadModal
                    studentsUploadComplete={this.fetchNewStudents}
                    studentsMap={this.state.studentsMap}
                  ></StudentUploadModal>
                </div>
                <div className="text-right search">
                  <input
                    value={this.state.searchKey}
                    onChange={this.searchChanged}
                    type="text"
                    placeholder="Search by name"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row section">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="teacher-table table-wrapper">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "25%" }} className="text-left">
                          <span>
                            First Name{" "}
                            <Sort
                              sort={this.state.sort.first_name}
                              property="first_name"
                              onSort={this.sort}
                            />
                          </span>
                        </th>
                        <th style={{ width: "30%" }} className="text-left">
                          <span>
                            Last Name
                            <Sort
                              sort={this.state.sort.last_name}
                              property="last_name"
                              onSort={this.sort}
                            />
                          </span>
                        </th>
                        <th style={{ width: "20%" }} className="text-left">
                          <span>
                            Gender
                            <Sort
                              sort={this.state.sort.gender}
                              property="gender"
                              onSort={this.sort}
                            />
                          </span>
                        </th>
                        <th style={{ width: "25%" }} className="text-left">
                          <span>
                            Ethnicity
                            <Sort
                              sort={this.state.sort.ethnicity}
                              property="ethnicity"
                              onSort={this.sort}
                            />
                          </span>
                        </th>
                        <th style={{ width: "25%" }} className="text-left">
                          <span>
                            SchoolLevel
                            <Sort
                              sort={this.state.sort.school_level}
                              property="school_level"
                              onSort={this.sort}
                            />
                          </span>
                        </th>
                        <th style={{ width: "10%" }} className="text-left">
                          <span>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.state.students}</tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={this.state.currentPage}
                  totalRecords={this.state.total}
                  pageSize={this.state.pageSize}
                  loadPage={this.renderStudentForPage}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Students;

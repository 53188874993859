import React, { Component }  from 'react';
import Sort from "./../Sort";
import Pagination from "./../Pagination";

export const InvitationTeachersTable = (props) => {

    
    return (
        <div className="row section">
            <div className="col-12">
                <div className="row">
                <div className="col-12">
                    <div className="teacher-table table-wrapper">
                    <table className="table">
                        <thead>
                        <tr>
                            <th style={{width: "27%"}}>
                            Teacher Name{" "}
                            <Sort
                                sort={props.sort.full_name}
                                property="full_name"
                                onSort={props.sort}
                            />
                            </th>
                            <th style={{width: "28%"}} >
                            Email Address{" "}
                            <Sort
                                sort={props.sort.email}
                                property="email"
                                onSort={props.sort}
                            />
                            </th>
                            <th style={{width: "25%"}} >
                            Status{" "}
                            <Sort
                                sort={props.sort.access}
                                property="access"
                                onSort={props.sort}
                            />
                            </th>
                            <th />

                            <th />         
    
                        </tr>
                        </thead>
                        <tbody>{props.teachers}</tbody>
                    </table>
                    </div>
                    <Pagination
                    type={props.type}
                    currentPage={props.currentPage}
                    totalRecords={props.totalRecords}
                    pageSize={props.pageSize}
                    loadPage={props.loadPage}
                    ></Pagination>
                </div>
                </div>
            </div>
            </div>
    )
}

import React from "react";
import BaseComponent from "./../BaseComponent";
import ObservationEntry from "./ObservationEntry";
import Pagination from "./../Pagination";

export default class ObservationTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      observations: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
    };
  }

  componentDidMount() {
    this.renderObservationsForPage(this.state.currentPage);
  }

  componentDidUpdate(prevProps) {
    if (this.props.observations.length !== prevProps.observations.length) {
      this.renderObservationsForPage(this.state.currentPage);
    }
  }

  renderObservationsForPage = (page) => {
    let start = (page - 1) * this.state.pageSize;
    let end = page * this.state.pageSize;
    let observationsForPage = this.props.observations
      .slice(start, end)
      .map((observation, index) => (
        <ObservationEntry
          key={index}
          name={observation.learning_area.name}
          count={observation.count}
        ></ObservationEntry>
      ));
    this.setState({ observations: observationsForPage, currentPage: page });
  };

  render() {
    return (
      <>
        <div className="observation-table table-wrapper">
          <span>What subjects are being observed</span>
          <table className="table mt-3">
            <thead>
              <tr>
                <th className="text-left">Subject Name</th>
                <th className="text-right">How many observations</th>
              </tr>
            </thead>
            <tbody>{this.state.observations}</tbody>
          </table>
        </div>
        <Pagination
          currentPage={this.state.currentPage}
          totalRecords={this.props.observations.length}
          pageSize={this.state.pageSize}
          loadPage={this.renderObservationsForPage}
        ></Pagination>
      </>
    );
  }
}

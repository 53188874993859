import React from "react";
import logo from "./../../images/Smata_Logo_V2.svg";
import google from "./../../images/Smata_Google.png";
import azure from "./../../images/Smata_Azure.png";
import apple from "./../../images/Smata_Apple.png";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import BaseComponent from "../BaseComponent";

class LoginPage extends BaseComponent {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = {
      isProcessing: params["processing"] ? true : false,
      error: false,
      success: false,
    };
  }

  componentDidMount() {


    if (this.state.isProcessing) {
      //temp settion is created with server. try getting tokens.
      console.log("RRRRR")
      this.fetchTokens();
    }
  }

  loginClass = () => {
    return this.state.isProcessing
      ? "d-none"
      : "login-btns text-center d-flex flex-column align-items-center mb-4";
  };

  loginState = () => {
    if (this.state.error) {
      return this.state.errorMsg ? this.state.errorMsg : "Please re-try";
    }
    return this.state.isProcessing ? "Please wait..." : "Login";
  };

  fetchTokens = () => {

    console.log("RRRRR22222")
    this.getRequest(
      process.env.REACT_APP_TOKENS_API,
      this.fetchTokensSuccess,
      this.fetchTokensError
    );
  };

  fetchTokensSuccess = (result) => {

    if (result.status === 200 && result.data.success) {
      this.context.authenticate(result.data, this.authSuccess, this.authFail);
    } else {

      this.setState({ isProcessing: false, error: true });
    }
  };

  authSuccess = () => {
    this.setState({ isProcessing: false, success: true });
  };

  authFail = (msg) => {
    this.setState({ isProcessing: false, error: true, errorMsg: msg });
  };

  fetchTokensError = () => {
    this.setState({ isProcessing: false, error: true });
  };

  render() {

    if (this.state.success) {
      return <Redirect to={this.context.getLastAttemptPath()} />;
    }
    return (
      <div className="container">
        <div className="row wrap-view align-items-center">
          <div className="col-12">
            <div className="login-img text-center">
              <img src={logo} alt="SMATA" />
            </div>
            <div className="text-center  my-4">
              <span className="login-title">{this.loginState()}</span>
            </div>
            <div className={this.loginClass()}>
              <a
                href={process.env.REACT_APP_GOOGLE_ATH_API}
                className="login-btn btn google mb-2"
              >
                <img src={google} alt="" />
                Login with Google
              </a>
              <a
                href={process.env.REACT_APP_AZURE_ATH_API}
                className="login-btn btn azure mb-2"
              >
                <img src={azure} alt="" />
                Login with Azure
              </a>
              <a href="" className="login-btn apple btn">
                <img src={apple} alt="" />
                Login with Apple
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LoginPage;

export function isAuthenticated() {
  return localStorage.getItem("access-token") ? true : false;
}

export function authenticate(data) {
  localStorage.setItem("access-token", data.access_token);
  localStorage.setItem("refresh-token", data.refresh_token);

  console.log(data);

  console.log("Setting cookie");

  document.cookie = `access-token=${data.access_token};refresh-token=${data.refresh_token};domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;

  console.log("COOKIE SET ");

}

export function setUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function handleNewAccessToken(accessToken) {
  localStorage.setItem("access-token", accessToken);
  document.cookie = `access-token=${accessToken};domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;

}

export function setLastAttemptPath(path) {
  localStorage.setItem("last-attempt", path);
}

export function getLastAttemptPath() {
  return localStorage.getItem("last-attempt");
}

export function logout() {
  clearAuth();
  window.location = "/login";
}

export function clearAuth() {

  console.log("clearAuth");
  localStorage.clear();
  document.cookie = `access-token=;domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
  document.cookie = `refresh-token=;domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;

  
}

export function setRefreshCookie() {
  let refreshToken = localStorage.getItem("refresh-token");
  document.cookie = `refresh-token=${refreshToken};domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
}

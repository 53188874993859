// import React, { Component } from "react";
// import { Gender, Ethnicity, School_level } from "./../../utils/Enums";
// class StudentEntry extends Component {
//   render() {
//     return (
//       <tr>
//         <td className="text-left">{this.props.firstName}</td>
//         <td className="text-left">{this.props.lastName}</td>
//         <td className="text-left">{Gender[this.props.gender]}</td>
//         <td className="text-left">{Ethnicity[this.props.ethnicity]}</td>
//         <td className="text-left">{School_level[this.props.school_level]}</td>
//       </tr>
//     );
//   }
// }

// export default StudentEntry;
import React, { Component } from "react";
import { Gender, Ethnicity, School_level } from "./../../utils/Enums";
import { Link } from "react-router-dom";
import { Form, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Icon } from "../Icon";
import editIcon from "../../images/Smata_EdtiLearningAreas.png";
import tickIcon from "../../images/Smata_Tick2.png";
import axios from "axios";

import { GenderDisplay, EthnicityDisplay } from "../../utils/Enums";
import CustomSelect from "./../Select";

class StudentEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inLineEdit: false,
      checked: false,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      gender: this.props.gender,
      school_level: this.props.school_level,
      ethnicity: this.props.ethnicity,
    };
  }

  genderOptions = [
    { optionName: "Select Gender", optionVal: "" },
    { optionName: "Female", optionVal: "0" },
    { optionName: "Male", optionVal: "1" },
    { optionName: "Other", optionVal: "2" },
  ];

  ethnicityOptions = [
    { optionName: "Select Ethnicity", optionVal: "" },
    { optionName: "European/Pakeha", optionVal: "0" },
    { optionName: "Maori", optionVal: "1" },
    { optionName: "Pasifika", optionVal: "2" },
    { optionName: "Asian", optionVal: "3" },
    { optionName: "MELAA", optionVal: "4" },
    { optionName: "Other", optionVal: "5" },
  ];

  onGenderSelect = (option) => {
    this.setState({ gender: Gender[option] });
  };

  onEthnicitySelect = (option) => {
    this.setState({ ethnicity: Ethnicity[option] });
  };

  saveStudent = () => {
    let url = `${process.env.REACT_APP_API_ROOT}/api/v1/students/edit`;

    console.log(this.props.student_id);

    axios
      .post(
        url,

        {
          student_id: this.props.student_id,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          gender:
            typeof this.state.gender == "string"
              ? Gender[this.state.gender]
              : this.state.gender,
          ethnicity:
            typeof this.state.ethnicity == "string"
              ? Gender[this.state.ethnicity]
              : this.state.ethnicity,
          school_level: this.state.school_level,
        },
        { withCredentials: true }
      )
      .then(async (response) => {
        console.log(response);
        await this.setState((prevstate) => ({
          inLineEdit: !prevstate.inLineEdit,
        }));
      })
      .catch((error) => {
        alert("Error while editing student: " + error.response.data.message);
      });
  };

  inLineEditFunc = async (id, name) => {
    await this.setState((prevstate) => ({ inLineEdit: !prevstate.inLineEdit }));

    if (this.props.modal == true) {
      this.props.handleSave(this.props.index, [
        this.state.firstName,
        this.state.lastName,
        Gender[this.state.gender],
        Ethnicity[this.state.ethnicity],
        this.state.school_level,
      ]);
    }
  };

  handleChange = (e) => {
    switch (e.target.placeholder) {
      case "First Name":
        this.setState({ firstName: e.target.value });
        break;
      case "Last Name":
        this.setState({ lastName: e.target.value });
        break;
      case "Gender":
        this.setState({ gender: Gender[e.target.value] });
        break;
      case "Ethnicity":
        this.setState({ ethnicity: e.target.value });
        break;
    }
  };

  handleSelect = (e, type) => {
    if (type === "gender") {
      this.setState({ gender: e });
    } else if (type === "ethnicity") {
      this.setState({ ethnicity: e });
    } else if (type === "school_level") {
      this.setState({ school_level: e });
    }
  };

  handleCheckbox = (e, type) => {
    this.props.handleSingleCheckbox({ Add: e.target.value });
  };

  RenderColumns = () => {
    if (this.state.inLineEdit) {
      return (
        <>
          <td className="text-left" style={{ padding: "0px 5px 0px 0px" }}>
            <input
              className="w-100"
              type="text"
              value={this.state.firstName}
              onChange={this.handleChange}
              placeholder="First Name"
            />
          </td>
          <td className="text-left" style={{ padding: "0px 5px 0px 0px" }}>
            <input
              type="text"
              className="w-100"
              value={this.state.lastName}
              onChange={this.handleChange}
              placeholder="Last Name"
            />
          </td>
          <td className="text-left" style={{ padding: "0px" }}>
            <Dropdown
              onSelect={(e) => {
                this.handleSelect(e, "gender");
              }}
              style={{ width: "90px !important", padding: "0px" }}
            >
              <Dropdown.Toggle
                variant="Primary"
                id="dropdown-basic"
                style={{ width: "90px", color: "green" }}
              >
                {this.state.gender}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.genderOptions.map((gender, index) => {
                  if (index >= 1)
                    return (
                      <Dropdown.Item key={index} eventKey={gender.optionName}>
                        {gender.optionName}
                      </Dropdown.Item>
                    );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <td
            onSelect={(e) => {
              this.handleSelect(e, "ethnicity");
            }}
            className="text-left"
            style={{ padding: "0px" }}
          >
            <Dropdown
              onSelect={(e) => {
                this.handleSelect(e, "ethnicity");
              }}
              style={{ width: "140px !important", padding: "0px" }}
            >
              <Dropdown.Toggle
                variant="Primary"
                id="dropdown-basic"
                style={{ width: "120px", color: "green" }}
              >
                {this.state.ethnicity === "European/Pakeha"
                  ? "Eur/Pakeha"
                  : this.state.ethnicity}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.ethnicityOptions.map((ethnicity, index) => {
                  if (index >= 1)
                    return (
                      <Dropdown.Item
                        key={index}
                        eventKey={ethnicity.optionName}
                      >
                        {ethnicity.optionName}
                      </Dropdown.Item>
                    );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <td
            onSelect={(e) => {
              this.handleSelect(e, "school_level");
            }}
            className="text-left"
            style={{ padding: "0px" }}
          >
            <Dropdown
              onSelect={(e) => {
                this.handleSelect(e, "school_level");
              }}
              style={{ width: "140px !important", padding: "0px" }}
            >
              <Dropdown.Toggle
                variant="Primary"
                id="dropdown-basic"
                style={{ width: "120px", color: "green" }}
              >
                {this.state.school_level}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Array.from(Array(14).keys()).map((_, index) => {
                  if (index >= 1)
                    return (
                      <Dropdown.Item key={index} eventKey={index}>
                        {index}
                      </Dropdown.Item>
                    );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </td>

          <td className="text-left">
            <span>
              <Icon
                id={this.props.studentId}
                handleClick={
                  this.props.modal == false
                    ? this.saveStudent
                    : this.inLineEditFunc
                }
                styling={null}
                width={34}
                icon={tickIcon}
                height={38}
                name=""
                color="#61E890"
              />
            </span>
          </td>
        </>
      );
    } else {
      return (
        <>
          <td className="text-left">
            {this.state.firstName === "" ? (
              <p style={{ color: "red" }}>?</p>
            ) : (
              this.state.firstName
            )}
          </td>
          <td className="text-left">
            {this.state.lastName === "" ? (
              <p style={{ color: "red" }}>?</p>
            ) : (
              this.state.lastName
            )}
          </td>
          <td className="text-left">
            {Gender[this.state.gender] === undefined ? (
              <p style={{ color: "red" }}>{this.state.gender}</p>
            ) : (
              Gender[this.state.gender]
            )}
          </td>
          <td className="text-left">
            {Ethnicity[this.state.ethnicity] === undefined ? (
              <p style={{ color: "red" }}>{this.state.ethnicity}</p>
            ) : (
              Ethnicity[this.state.ethnicity]
            )}
          </td>
          <td className="text-left">
            {School_level[this.state.school_level] === undefined ? (
              <p style={{ color: "red" }}>{this.state.school_level}</p>
            ) : (
              this.state.school_level
            )}
          </td>
          <td>
            <span>
              <Icon
                handleClick={this.inLineEditFunc}
                styling={null}
                width={34}
                icon={editIcon}
                height={38}
                name=""
                color="#4bc2bf"
              />
            </span>
          </td>
        </>
      );
    }
  };

  render() {
    let css;

    if (this.props.checked === "Add All") {
      css = "007bff";
    } else {
      css = "red";
    }

    return (
      <tr>
        {this.RenderColumns()}
        {this.props.duplicate ? (
          <>
            <td
              className="justify-content-center"
              style={{ paddingRight: "50px", color: "blue" }}
            >
              <Form.Check
                onChange={(e) => this.handleCheckbox(e, "Add")}
                type="checkbox"
                style={{ color: `${css}` }}
                inline
                className="my-1 mr-sm-2 overflow-hidden"
                id={this.props.studentId + 1}
                value={this.props.firstName + this.props.lastName}
                label={
                  this.props.checked === "Add All"
                    ? "Add Duplicate"
                    : "Duplicate"
                }
                name="Add"
                checked={
                  this.props.checked === "Add All"
                    ? true
                    : this.props.checked === "Add"
                    ? true
                    : false
                }
                custom
              />
            </td>
          </>
        ) : null}
      </tr>
    );
  }
}

export default StudentEntry;

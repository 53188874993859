import React, { Component } from "react";

class CustomSelect extends Component {
  // props data={optionName=, optionVal=} onSelect={args:: option object} data={option[]} selectedOption={default option object}
  constructor(props) {
    super(props);
    this.state = { options: [], selectedOption: {}, expanded: false };
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    // set default select
    let options = this.props.data.map((option) => (
      <OptionView
        onSelect={this.onSelect}
        key={option.optionVal}
        defaultVal={this.props.selectedOption.optionVal}
        option={option}
      ></OptionView>
    ));
    this.setState({ options });
    document.addEventListener("mousedown", this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (this.selectRef.current.contains(event.target)) {
      return;
    }
    this.setState({ expanded: false });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleDocumentClick);
  }

  onSelect = (option) => {
    this.setState({ expanded: false });
    this.props.onSelect(option);
  };

  toggleSelectBox = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  selectBoxClass = () => {
    return this.state.expanded ? "select-items" : "select-items select-hide";
  };

  render() {
    return (
      <div ref={this.selectRef}>
        <div
          onClick={this.toggleSelectBox}
          className={`select-selected ${this.props.selectedOption.optionVal}`}
        >
          <span>{this.props.selectedOption.optionName}</span>
        </div>
        <div className={this.selectBoxClass()}>{this.state.options}</div>
      </div>
    );
  }
}

const OptionView = function (props) {
  return (
    <div
      onClick={() => props.onSelect(props.option)}
      className={
        props.option.optionVal === props.defaultVal ? "same-as-selected" : ""
      }
    >
      {props.option.optionName}
    </div>
  );
};

export default CustomSelect;

import React from "react";
import TeacherForm from "../teachers/TeacherForm";

class TeachersPage extends React.Component {
  render() {
    return <TeacherForm></TeacherForm>;
  }
}

export default TeachersPage;

import React from "react";
import "./App.css";
import LoginPage from "./components/pages/Login";
import MainMenu from "./components/Menu";
import DataContext from "./context/DataContext";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      < >
        <Router id="appElement">
          <DataContext>
            <Switch>             
              <Route
                path={[
                  "/",
                  "/teachers",
                  "/students",
                  "/add-teacher",
                  "/add-student",
                  "/schools",
                  "/schools/:id/teachers",
                ]}
                exact
                component={MainMenu}
              />
              <Route path="/login" exact component={LoginPage} />
              <Redirect path="*" to="/" />
            </Switch>
          </DataContext>
        </Router>
      </>
    );
  }
}

export default App;

import BaseComponent from "../BaseComponent";
import React from "react";
import uploadCsv from "../../images/uploadCSV.PNG";
import { Modal, Button, ProgressBar, Table } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import Students from "./Students";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Modal.setAppElement(document.getElementById('root'));
export default class StudentUploadModal extends BaseComponent {
  constructor() {
    super();
    this.state = {
      refreshToggle: false,
      show: false,
      success: false,
      step: "selection",
      barValue: 0,
      students: null,
      studentsRaw: null,
      uploading: false,
      uploaded: false,
      errorMessage: "",
      duplicates: false,
      studentsArray: [],
    };

    this.fileSelector = React.createRef();
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  openFileBrowser = () => {
    this.setState({ error: false, success: false });
    this.fileSelector.current.click();
  };

  resetUploader = () => {
    this.resetState(true);
    this.openFileBrowser();
  };

  resetState = (showModel = false) => {
    this.setState({
      step: "selection",
      barValue: 0,
      students: null,
      studentsRaw: null,
      uploading: false,
      uploaded: false,
      errorMessage: "",
      show: showModel,
    });
  };

  done = () => {
    this.setState({ step: "done" });
    setTimeout(() => {
      this.resetState();
    }, 2000);
  };

  handleStudentsFromFile = () => {
    const { studentsArray } = this.state;
    const toPush = [];
    for (let student of studentsArray) {
      const newStudent = {
        first_name: student.first_name,
        last_name: student.last_name,
        gender: student.gender,
        ethnicity: student.ethnicity,
        school_level: student.school_level,
      };
      if (student.duplicate && student.checked === "Add") {
        const oldStudent =
          this.props.studentsMap[student.first_name + student.last_name];
        if (JSON.stringify(oldStudent) !== JSON.stringify(newStudent))
          toPush.push(newStudent);
      } else {
        toPush.push(newStudent);
      }
    }

    let url = process.env.REACT_APP_ADD_STUDENT_BULK_API;
    axios
      .post(url, { students: toPush }, { withCredentials: true })
      .then((result) => {

        if (result.status === 200 && result.data.success) {
          // this.setState({ success: true, step: "final" });
          this.props.studentsUploadComplete();
          this.done();
        } else {
          this.setState({ error: true, step: "final" });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.handleStudentsFromFile, [toPush]);
        } else {
          this.setState({ error: true });
        }
      });
  };

  renderButton = () => {
    if (this.state.step === "selection") {
      return (
        <a
          onClick={this.openFileBrowser}
          className="btn uploadd justify-content-end"
        >
          Upload CSV
        </a>
      );
    } else if (this.state.step === "processing") {
      return <a className="btn uploadd justify-content-end">Uploading</a>;
    } else if (this.state.step === "final") {
      return (
        <>
          <a
            onClick={this.resetUploader}
            className="btn uploadd"
            style={{ marginRight: "80px" }}
          >
            Upload Again
          </a>

          <a
            onClick={() => {
              if (!this.state.error) {
                this.handleStudentsFromFile();
              }
            }}
            style={{
              backgroundColor: `${this.state.error ? "#EA7373" : "#9BD6FF"} `,
            }}
            className="btn uploadd justify-content-end"
          >
            {this.state.error ? "Error" : "Push Changes"}
          </a>
        </>
      );
    } else if (this.state.step === "done") {
      return (
        <a
          style={{ backgroundColor: "#9BFFAE" }}
          className="btn uploadd justify-content-end"
        >
          Done Uploading!
        </a>
      );
    }
  };

  processFile = (event) => {
    this.setState({ step: "processing", uploading: true, show: true });

    const fileList = event.target.files;

    for (const file of fileList) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this.processContent(event.target.result);
      });
      reader.readAsText(file);
    }
  };

  processContent = (content) => {
    setTimeout(() => {
      this.setState({ barValue: 15 });

      setTimeout(() => {
        this.setState({ show: true, barValue: 30 });
        if (content) {
          let studentLines = content
            .replace(/\r/gm, "")
            .split("\n")
            .filter((e) => e);
          let studentsArray = [];
          // let students = [];
          let students = new Map();

          for (let i = 0; i < studentLines.length; i++) {
            const line = studentLines[i].split(",");
            if (line.length !== 5) {
              // if(!this.error)
              this.setState({
                errorMessage: `${i + 1}, Missing one of the properties`,
                error: true,
              });
              break;
            }

            const name = line[0] + line[1];

            if (!this.state.duplicates) {
              if (this.props.studentsMap[name]) {
                this.setState({ duplicates: true });
              }
            }

            if (!students.has(name)) {
              students.set(name, studentLines[i]);
            }
            // students.push(studentLines[i]);
          }

          const studentsCopy = [...students.values()];
          const studentsValues = [...students.values()];

          students = this.getStudentEntry(studentsValues, studentsArray, "");

          // if (studentLines.length !== students.length) {
          //   this.setState({ error: true, step: "final", barValue: 40 });
          //   return;
          // }

          this.setState(
            {
              uploaded: true,
              students: studentsValues,
              studentsRaw: studentsCopy,
              step: "final",
            },
            () => {
              // this.handleStudentsFromFile(students);
            }
          );
        } else {
          this.setState({ error: true, step: "final" });
        }
      }, 1400);
    }, 1200);
  };

  getStudentEntry = (students, studentsArray, type) => {

    let studentArray = this.state.studentsArray.slice();
    let newStudentArray = [];
    const expressions = ["First Name", "Last Name", "Gender", "Ethnicity", "School_level"];
    const ethneticies = [
      "EU",
      "Pak",
      "Maori",
      "Pas",
      "MELAA",
      "Asian",
      "other",
    ];
    const school_level = new RegExp(/^([1-9]|1[0123])$/);
    //       const school_level = new RegExp(/^Year|year\s([1-9]|1[01234])$/);

    const genders = ["Male", "Female", "other"];

    const re_expressions = new RegExp(expressions.join("|"), "i");
    const re_ethneticies = new RegExp(ethneticies.join("|"), "i");
    const re_genders = new RegExp(genders.join("|"), "i");

    const isHeaderPresent =
      students.length > 0 &&
      students[0].split(",").some((column) => {
        return re_expressions.test(column);
      });

    if (isHeaderPresent) {
      students = students.slice(1);
    }

    let error = false;

    students.map((line, index) => {
      if (this.state.barValue <= 100) {
        this.setState({ barValue: 100 });
      }

      let lineData = line.split(",").map((item) => item.trim());

      let student = {};

      if (!lineData[2].match(re_genders) && !this.state.error && !error) {
        error = true;
        this.setState({
          errorMessage: `${
            index + 1
          }, invalid gender type, Allowed Types include : [Male, Female, Other]`,
          error: true,
        });
      }
      if (!lineData[3].match(re_ethneticies) && !this.state.error & !error) {
        error = true;
        this.setState({
          errorMessage: `${
            index + 1
          }, invalid ethinicity type!, Allowed Types include : [Select Ethnicity, European/Pakeha, Maori", Pasifika", Asian", MELAA", Other"]`,
          error: true,
        });
      }

      if (!lineData[4].match(school_level) && !this.state.error & !error) {
        error = true;
        this.setState({
          errorMessage: `${
            index + 1
          }, invalid school_level type!, Allowed Types include : [Select School_Level Year 1-13 or Other"]`,
          error: true,
        });
      }

      //       student["ethnicity"] =
       // lineData[3] === ""
      //  ? "?"
      //  : lineData[3].charAt(0).toUpperCase() +
      //    lineData[3].slice(1).toLowerCase();
      //  student["ethnicity"] = lineData[3].charAt(0).toUpperCase() + lineData[3].slice(1).toLowerCase();
      // student["gender"] = lineData[2].charAt(0).toUpperCase() + lineData[2].slice(1).toLowerCase();

      /**  student["ethnicity"] =
        lineData[3] === ""
          ? "?"
          : lineData[3].charAt(0).toUpperCase() +
            lineData[3].slice(1).toLowerCase(); */
      student["id"] = index;
      student["first_name"] = lineData[0];
      student["last_name"] = lineData[1];
      student["gender"] =
        lineData[2] === ""
          ? "?"
          : lineData[2].charAt(0).toUpperCase() +
            lineData[2].slice(1).toLowerCase();
      student["ethnicity"] = lineData[3]
      student["school_level"] =
        lineData[4] === ""
          ? "?"
          : lineData[4].charAt(0).toUpperCase() +
            lineData[4].slice(1).toLowerCase();

      student["checked"] =
        this.state.studentsArray[index]?.checked === "Add" ? "Add" : "";
      student["duplicate"] =
        this.props.studentsMap &&
        this.props.studentsMap[lineData[0] + lineData[1]]
          ? true
          : false;

      if (!student["first_name"])
        this.setState({
          errorMessage: `${index + 1}, missing FirstName type`,
          error: true,
        });
      if (!student["last_name"])
        this.setState({
          errorMessage: `${index + 1}, missing LastName type`,
          error: true,
        });
      if (!student["gender"] && !this.state.error & !error)
        this.setState({
          errorMessage: `${index + 1}, missing gender type`,
          error: true,
        });
      if (!student["ethnicity"] && !this.state.error & !error)
        this.setState({
          errorMessage: `${index + 1}, missing ethinicity type`,
          error: true,
        });
      if (!student["school_level"] && !this.state.error & !error)
        this.setState({
          errorMessage: `${index + 1}, missing school_level type`,
          error: true,
        });

      if (type === "Add All") {
        student["checked"] = "Add";
      } else if (type === "UndoCheck All") {
        student["checked"] = "";
      } else if (type["Add"]) {
        const fullName = type["Add"];
        const fullName2 = student["first_name"] + student["last_name"];

        if (fullName === fullName2) {
          if (student["checked"] === "Add") {
            student["checked"] = "";
          } else {
            student["checked"] = "Add";
          }
        }
      }
      newStudentArray.push(student);
    });

    this.setState({ studentsArray: newStudentArray });
  };

  handleSave = (index, data) => {

    const students = [...this.state.studentsRaw];

    // let mappedStudent = Object.entries({...students[index-1]}).map(([key, value]) => []);
    students[index] = data.slice(0, data.length).join(",");

    this.setState(
      { studentsRaw: students, errorMessage: "", error: false },
      () => {
        const mappedStudents = this.getStudentEntry(
          this.state.studentsRaw,
          [],
          ""
        );

        this.setState({ students: mappedStudents });
      }
    );
  };

  render() {
    return (
      <div>
        <a
          onClick={this.handleShow}
          className="btn uploadd"
          style={{ color: "#4068ae" }}
        >
          Upload CSV
        </a>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ position: "absolute", left: "40%" }}>
              Upload a CSV
            </Modal.Title>

            {/* <CloseButton variant="white" aria-label="Close" /> */}
          </Modal.Header>

          <Modal.Body className="text-center">
            <h5
              style={{
                color: "red",
                textAlign: "center",
                wordWrap: "break-word",
              }}
            >
              {this.state.error
                ? "Error in file on line " + this.state.errorMessage
                : ""}
            </h5>

            {!this.state.uploaded ? (
              <img src={uploadCsv} />
            ) : (
              <Students
                duplicates={this.state.duplicates}
                displayAll={true}
                students={this.state.students}
                studentsRaw={this.state.studentsRaw}
                studentsArray={this.state.studentsArray}
                handleCheckbox={this.handleCheckbox}
                handleSingleCheckbox={this.handleSingleCheckbox}
                handleSave={this.handleSave}
              />
            )}

            {this.state.uploading && !this.state.error ? (
              <ProgressBar
                animated
                now={this.state.barValue}
                style={{ marginTop: "10px", height: "20px" }}
              />
            ) : this.state.uploading && this.state.error ? (
              <ProgressBar
                variant="danger"
                animated
                now={50}
                style={{ marginTop: "10px", height: "20px" }}
              />
            ) : null}
          </Modal.Body>

          <Modal.Footer className="justify-content-between">
            <span className="top-inputs">
              <Button
                style={{ marginLeft: "0" }}
                onClick={this.handleClose}
                className="btn download"
              >
                <a
                  href={process.env.PUBLIC_URL + "/smata_sample_student_upload.csv"}
                  download
                >
                  Download example CSV{" "}
                </a>
              </Button>
            </span>
            <span className="top-inputs d-flex flex-row justify-content-end">
              {this.renderButton()}
            </span>

            <input
              onChange={(event) => {
                this.processFile(event);
                event.target.value = null;
              }}
              type="file"
              ref={this.fileSelector}
              className="d-none"
              accept=".csv"
            />
          </Modal.Footer>
        </Modal>
        {/* <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              centered
              size="xl"
              className=""
            >


      <div class="modal-header">
        <h2 class="modal-title text-center">Uploading a CSV</h2>
        <h5 class="modal-title">File must be in the following format and exported as a CSV file</h5>
      </div>
      <div class="modal-body">
        <img src="/uploadCSV.png"></img>
      </div>
      <div class="modal-footer">
          <a href="/smata_sample_student_upload.csv" download>
        <button type="button" class="btn download">Download example CSV </button></a>
        <UploadButton studentsUploadComplete={this.props.studentsUploadComplete} />
      </div>


               <Modal.Body className="text-center" >

               </Modal.Body>
              
            </Modal> */}
      </div>
    );
  }
}

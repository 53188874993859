import React, { Component }  from 'react';
import Sort from "./../Sort";
import Pagination from "./../Pagination";

export const TeachersTable = (props) => {

    return (
        <div className="row section">
            <div className="col-12">
                <div className="row">
                <div className="col-12">
                    <div className="teacher-table table-wrapper">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="text-left">
                            Teacher Name{" "}
                            <Sort
                                type={props.type}
                                sort={props.sort.full_name}
                                property="full_name"
                                onSort={props.sort}
                            />
                            </th>
                            <th className="text-left">
                            Email Address{" "}
                            <Sort
                                type={props.type}
                                sort={props.sort.email}
                                property="email"
                                onSort={props.sort}
                            />
                            </th>
                            <th className="text-left">
                            School Portal Access{" "}
                            <Sort
                                type={props.type}
                                sort={props.sort.access}
                                property="access"
                                onSort={props.sort}
                            />
                            </th>
                            <th className="text-left">
                            Verification{" "}
                            <Sort
                                type={props.type}
                                sort={props.sort.approved}
                                property="approved"
                                onSort={props.sort}
                            />
                            </th>
                            <th className="text-left">
                            Observations{" "}
                            <Sort
                                type={props.type}
                                sort={props.sort.totalObservations}
                                property="observations"
                                onSort={props.sort}
                            />
                            </th>
                        </tr>
                        </thead>
                        <tbody>{props.teachers}</tbody>
                    </table>
                    </div>
                    <Pagination
                    type={props.type}
                    currentPage={props.currentPage}
                    totalRecords={props.totalRecords}
                    pageSize={props.pageSize}
                    loadPage={props.loadPage}
                    ></Pagination>
                </div>
                </div>
            </div>
            </div>
    )
}

import React, { Component } from "react";
import axios from "axios";
import {
  setRefreshCookie,
  handleNewAccessToken,
  logout,
  authenticate,
  isAuthenticated,
  setUser,
  getUser,
  getLastAttemptPath,
  clearAuth,
} from "./Auth";

export const CurrentDataContext = React.createContext();

class DataContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appData: {
        authenticate: this.authencticateUser,
        refreshToken: this.refreshToken,
        isAuthorize: this.isAuthorize,
        user: getUser(),
        getLastAttemptPath: this.lastAttemptUrl,
      },
    };
  }

  isRefreshingToken = false;
  afterRefreshCallbacks = [];

  refreshToken = (callback, argsArry) => {
    if (callback) {
      this.afterRefreshCallbacks.push({ fn: callback, args: argsArry });
    }
    if (!this.isRefreshingToken) {
      // block multiple refresh requests
      this.isRefreshingToken = true;
      // confirm current refresh token is set to the cookie
      setRefreshCookie();
      axios
        .get(process.env.REACT_APP_REFRESH_AUTH_API, { withCredentials: true })
        .then((result) => {
          if (result.status === 200 && result.data.success) {
            handleNewAccessToken(result.data.access_token);
            // execute call backs
            this.afterRefreshCallbacks.forEach((callbackData) => {
              if (typeof callbackData.fn === "function") {
                if (callbackData.args) {
                  callbackData.fn(...callbackData.args);
                } else {
                  callbackData.fn();
                }
              }
            });
            // clear callbacks
            this.afterRefreshCallbacks = [];
            // flag refreshing end
            this.isRefreshingToken = false;
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            (error.response.status === 401 || error.response.status === 403 || error.response.status === 440)
          ) {
            // Unauthorized. Possible refresh token expiration. logout user
            logout();
          } else {
            console.log(error);
            logout();;
          }
        });
    }
  };

  isAuthorize = (authRole) => {

    console.log("authorizaiging")
    return (
      isAuthenticated() &&
      this.state.appData.user &&
      this.state.appData.user.roles.some( r => authRole.split(',').indexOf(r) > -1)
    );
  };

  authencticateUser = (data, successFn, errorFn) => {
    // set token cookies
    authenticate(data);
    // only users with head role can access portal
    let acceptedRoles = ['head', 'admin']
    axios
      .get(process.env.REACT_APP_USER_PROFILE_API, { withCredentials: true })
      .then((result) => {
        if (
          result.status === 200 &&
          result.data.data.roles.some(r => acceptedRoles.indexOf(r) > -1 )
        ) {
          setUser(result.data.data);
          this.setState((prevState) => ({
            appData: { ...prevState.appData, user: result.data.data },
          }));
          successFn();
        } else {
          clearAuth();
          errorFn("Your account is pending Approval!");
        }
      })
      .catch(() => {
        logout();
      });
  };

  lastAttemptUrl = () => {
    let url = getLastAttemptPath();
    if (!url) {
      if (this.state.appData.user?.roles?.indexOf("manager") > -1) {
        url = "/schools";
      } else {
        url = "/";
      }
    }
    return url;
  };

  render() {
    return (
      <CurrentDataContext.Provider value={this.state.appData}>
        {this.props.children}
      </CurrentDataContext.Provider>
    );
  }
}

export default DataContext;

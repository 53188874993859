import React from "react";
import axios from "axios";
import BaseComponent from "../BaseComponent";
import school from "./../../images/Smata_School-01.svg";
import Sort from "./../Sort";
import Pagination from "./../Pagination";
import SchoolEntry from "./SchoolEntry";
export default class AllSchools extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { 
      schools: null,
      schoolsRaw: null,
      currentPage: 1,
      total: 0,
      pageSize: 10,
      searchKey: "",
      sort: {  name: "", billing_email: "", id: "", approved: "", renewal_date: "", access: "", observations: "" },
      sortMethod: "",
      sortProperty: "",
    };
  }

  componentDidMount() {
    this.fetchAllSchools(this.state.currentPage);
  }

  fetchAllSchools = () => {
    let url = `${process.env.REACT_APP_SCHOOLS_API}?page_number=0&page_size=100000`;
    axios
      .get(url, { withCredentials: true })
      .then((result) => {
        if (result.status === 200) {
          let { schools, total } = result.data;
          this.setState({
            schoolsRaw: schools,
            total,
          });
          this.renderSchoolsForPage(this.state.currentPage);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 440)
        ) {
          // Unauthorized. Possible access token expiration
          this.context.refreshToken(this.fetchAllSchools);
        } else {
          console.log(error);
        }
      });
  }

  searchChanged = (event) => {
    this.setState({ searchKey: event.target.value }, () =>
      this.renderSchoolsForPage(1)
    );
  };

  sort = (proporty, sort) => {
    const newSortState = this._getFreshSortState();
    newSortState[proporty] = sort;
    this.setState(
      { sort: newSortState, sortProperty: proporty, sortMethod: sort },
      () => this.renderSchoolsForPage(this.state.currentPage)
    );
  };

  _getFreshSortState = () => {
    return {
      name: "",
      billing_email: "",
      id: "",
      approved: "",
      renewal_date: "",
      access: "",
      observations: ""
    };
  };

  renderSchoolsForPage = (page) => {
    
    let start = (page - 1) * this.state.pageSize;
    let end = page * this.state.pageSize;
    let searchKey = this.state.searchKey.toLowerCase();
    let schoolsSearched = this.state.schoolsRaw.filter((school) =>
      this.state.searchKey
        ? (school.name &&
          school.name.toLowerCase().includes(searchKey)) ||
          (school.admin?.billing_email &&
            school.admin.billing_email.toLowerCase().includes(searchKey)) ||
          (school.id &&
            school.id.toLowerCase().includes(searchKey))
        : true
    );
    let schoolsSorted = schoolsSearched;
    if (this.state.sortProperty && this.state.sortMethod) {
      schoolsSorted = schoolsSearched.sort((a, b) => {
        let propA, propB;
        if (this.state.sortProperty === "billing_email") {
          propA = a.admin?.billing_email;
          propB = b.admin?.billing_email;
        } else if (this.state.sortProperty === "access") {
          propA = a.admin?.free_access ? "yes" : "no";
          propB = b.admin?.free_access  ? "yes" : "no";
        } else if (this.state.sortProperty === "approved") {
          propA = a.admin?.school_enabled ? "Approved" : "Waiting";
          propB = b.admin?.school_enabled ? "Approved" : "Waiting";
        } else {
          propA = a[this.state.sortProperty];
          propB = b[this.state.sortProperty];
        }
        if (this.state.sortMethod === "asc") {
          return propA && !propB
            ? 1
            : propA && propB && propA.toLowerCase() > propB.toLowerCase()
            ? 1
            : -1;
        } else {
          return propA && !propB
            ? -1
            : propA && propB && propA.toLowerCase() > propB.toLowerCase()
            ? -1
            : 1;
        }
      });
    }
    let schoolsForPage = schoolsSorted
      .slice(start, end)
      .map((school) => (
        <SchoolEntry
          key={school.id}          
          id={school.id}
          access={ school.admin?.free_access }
          approved={school.admin?.school_enabled || false}
          schoolName={school.name}
          schoolEmail={school.id}
          billingEmail={school.admin?.billing_email}
          renewDate={school.admin?.renewal_date}
          observations={school.observations}
          reload={this.fetchAllSchools}
        ></SchoolEntry>
      ));
    this.setState({
      schools: schoolsForPage,
      currentPage: page,
      total: schoolsSorted.length,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 page-heading d-flex align-items-center">
            <div className="heading-img">
              <img src={school} alt="" />
            </div>
            <div className="align-self-center">
              <div>
                <h1 className="first-title">Manage Schools</h1>
              </div>
            </div>

            <div className="top-inputs ml-auto">
              <div className="text-right search">
                <input
                  value={this.state.searchKey}
                  onChange={this.searchChanged}
                  type="text"
                  placeholder="Search by school name"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row section">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="teacher-table table-wrapper">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-left">
                          School Name{" "}
                          <Sort sort={this.state.sort.name} property="name" onSort={this.sort} />
                        </th>
                        <th className="text-left">
                          Billing Email{" "}
                          <Sort sort={this.state.sort.billing_email} property="billing_email" onSort={this.sort} />
                        </th>
                        <th className="text-left">
                          School Email{" "}
                          <Sort sort={this.state.sort.id} property="id" onSort={this.sort} />
                        </th>
                        <th className="text-left">
                          Verify School{" "}
                          <Sort sort={this.state.sort.approved} property="approved" onSort={this.sort} />
                        </th>
                        <th className="text-left">
                          Renewal Date{" "}
                          <Sort sort={this.state.sort.renewal_date} property="renewal_date" onSort={this.sort} />
                        </th>
                        <th className="text-left">
                          Free access{" "}
                          <Sort sort={this.state.sort.access} property="access" onSort={this.sort} />
                        </th>
                        <th className="text-left">
                          Observations{" "}
                          <Sort sort={this.state.sort.observations} property="observations" onSort={this.sort} />
                        </th>
                        <th className="text-left">View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.schools}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={this.state.currentPage}
                  totalRecords={this.state.total}
                  pageSize={this.state.pageSize}
                  loadPage={this.renderSchoolsForPage}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

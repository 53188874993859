import arts from "./../images/Smata_Icon_1-01.svg";
import artsBlue from "./../images/Smata_Icon_1_Blue-01.svg";
import english from "./../images/Smata_Icon_2-01.svg";
import englishBlue from "./../images/Smata_Icon_2_Blue-01.svg";
import sport from "./../images/Smata_Icon_3-01.svg";
import sportBlue from "./../images/Smata_Icon_3_Blue-01.svg";
import science from "./../images/Smata_Icon_4-01.svg";
import scienceBlue from "./../images/Smata_Icon_4_Blue-01.svg";
import languages from "./../images/Smata_Icon_5-01.svg";
import languagesBlue from "./../images/Smata_Icon_5_Blue-01.svg";
import maths from "./../images/Smata_Icon_6-01.svg";
import mathsBlue from "./../images/Smata_Icon_6_Blue-01.svg";
import social from "./../images/Smata_Icon_7-01.svg";
import socialBlue from "./../images/Smata_Icon_7_Blue-01.svg";
import it from "./../images/Smata_Icon_8-01.svg";
import itBlue from "./../images/Smata_Icon_8_Blue-01.svg";
import other from "./../images/Smata_Icon_9-01.svg";
import otherBlue from "./../images/Smata_Icon_9_Blue-01.svg";
import compitencies from "./../images/Smata_Icon_10-01.svg";
import compitenciesBlue from "./../images/Smata_Icon_10_Blue-01.svg";
import values from "./../images/Smata_Icon_11-01.svg";
import valuesBlue from "./../images/Smata_Icon_11_Blue-01.svg";

export const catToWhiteImageMap = {
  0: maths,
  1: arts,
  2: english,
  3: sport,
  4: science,
  5: languages,
  6: social,
  7: it,
  8: compitencies,
  9: values,
  10: other,
};

export const catToBlueImageMap = {
  0: mathsBlue,
  1: artsBlue,
  2: englishBlue,
  3: sportBlue,
  4: scienceBlue,
  5: languagesBlue,
  6: socialBlue,
  7: itBlue,
  8: compitenciesBlue,
  9: valuesBlue,
  10: otherBlue,
};
